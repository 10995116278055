import React, { Component } from 'react';

class PageNotFound extends Component{
    render(){
        return(
            <div className="bg-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 px-0 text-center thanks">
                            <h1 className="mb-6">Page not found!</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageNotFound;