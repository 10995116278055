import React, { Component } from 'react';
import { connect } from 'react-redux';
import contentItemNames from '../constants/contentItemNames';
import apiHelper from '../helpers/apiHelper';

//component
import Loading from './Loading';

class PrivacyPolicy extends Component{
    constructor(){
        super();

        this.state = {
            error_message : '',
            is_loading : false,
            body : ''
        }

        this.getPrivacyPolicy = this.getPrivacyPolicy.bind(this);
    }

    componentWillMount(){
        this.getPrivacyPolicy(this.props.site.id);
    }

    getPrivacyPolicy(siteId){
        //set loading
        this.setState({
            is_loading : true
        });

        apiHelper.getContenItem(contentItemNames.PRIVACY_POLICY, siteId, (res) => {
            //check if valid
            if(res.is_valid){
                //response success
                this.setState({
                    body : res.data.body,
                    error_message : '',
                    is_loading : false
                });
            }else{
                //response failed
                this.setState({
                    error_message: res.message,
                    is_loading : false
                });
            }
        });
    }

    renderBodyContents = () => {
        return {__html: this.state.body}
    }

    render(){
        return(
            <div className="modal fade" id="privacyPolicyModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content rounded-0">
                        <div className="modal-body modal-privacy">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 wrap-privacy">
                                        { this.state.is_loading ? <Loading /> : (
                                            <div dangerouslySetInnerHTML={this.renderBodyContents()}></div>
                                        ) }
                                    </div>
                                </div>
                                <div className="row wrap-btn-step">
                                    <button type="button" className="btn btn-secondary mx-auto btn-step" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer
})

export default connect(mapStateToProps, {

})(PrivacyPolicy);