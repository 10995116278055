import React, { Component } from 'react';
import { apiUrl, apiSettings, isDebug } from '../../config';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Link } from 'react-router-dom';

//actions
import { login } from '../../redux/actions/userAction';

//component
import TermsAndConditions from '../TermsAndConditions';
import CheckEmail from './CheckEmail';
import PrivacyPolicy from '../PrivacyPolicy';
import Loading from '../Loading';

//url
const signUpUrl = apiUrl + 'user/signup';
const getLocationTypeUrl = apiUrl + 'Location/GetLocationTypes';
const getStateOrRegionUrl = apiUrl + 'Location/GetStateOrRegion';
const getLocationNameUrl = apiUrl + 'Location/GetLocationsByLocationTypeStateRegion';

//store not listed
const storeNotListedLabel = "Store not listed";

class Signup extends Component{
    constructor(){
        super();

        this.state = {
            location_types : [],
            location_types_loading : false,
            states_or_regions : [],
            states_or_regions_loading : false,
            location_names : [],
            location_names_loading : false,
            site_id: 0,
            showUserInfoForm: false, //show user info form
            locationTypeOtherSelected: false,
            locationTypeEnum: 0,
            locationTypeName: '',
            showStateOrRegionSelect: false, //show state or region select
            showLocationNameSelect: false, //show location name select
            showStoreSelect: true, //show select store
            isStoreNotListed : false,

            //Body Content
            other_store_name : '',
            state: '',
            region: '',
            family_channel: '',
            selected_dealer: {},
            dealer_name: '',
            first_name: '',
            last_name: '',
            phone_area: '',
            phone_number: '',
            email_address: '',
            confirm_email_address: '',
            password: '',
            confirm_password: '',
            notification_email: null,
            notification_sms: null,
            notification_error: "",

            //DOM
            is_loading: false,
            is_success: false,
            error_messages: [],
            show_terms_and_conditions: false,
            accept_terms_and_conditions: false,
        };

        //bind function
        this.handleLocationTypeChange = this.handleLocationTypeChange.bind(this);
        this.handleStateOrRegionChange = this.handleStateOrRegionChange.bind(this);
        this.handleLocationNameChange = this.handleLocationNameChange.bind(this);
        this.handleLocationOtherChange = this.handleLocationOtherChange.bind(this);
        this.handleStoreNotListedChange = this.handleStoreNotListedChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setNotificationMethod = this.setNotificationMethod.bind(this);
        this.setPhoneDefault = this.setPhoneDefault.bind(this);
    }

    componentDidMount(){
        //set site id
        this.setState({ site_id: this.props.site.id });

        //check site path, if lube mobile doesnt have to get location and state
        if(this.props.site.site_path.toLowerCase()!=='/lube-mobile'){
            //get location types
            this.getLocationTypes();

            //get states or region
            this.getStatesOrRegions(this.props.site.id);
        }else{
            //show user form
            this.setState({
                showUserInfoForm : true,
                showStoreSelect : false
            })
        } 

        //set default phone area
        this.setPhoneDefault();
    }

    setPhoneDefault(){
        let countryCode = this.props.site.country_code.toLowerCase();
        if(countryCode==='au' || countryCode==='bmsa' || countryCode==='lube-mobile'){
            this.setState({ phone_area : '+61' });
        }else if(countryCode==='nz'){
            this.setState({ phone_area : '+64' });
        }
    }

    getLocationTypes(){
        //set loading
        this.setState({ location_types_loading : true });

        //get data from API
        fetch(getLocationTypeUrl, {
            method : 'POST',
            body : JSON.stringify({
                secret_key : apiSettings.secret_key,
                token : apiSettings.token,
                site_id: this.props.site.id
            })
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(isDebug){
                        console.log(data);
                    }
                    if(data.is_valid){
                        //response success
                        this.setState({
                            location_types : data.data,
                            error_messages : [],
                            location_types_loading : false
                        });
                    }else{
                        //response failed
                        this.setState({
                            error_messages: data.error_messages,
                            location_types_loading : false
                        });
                    }
                });
            }
            else{
                //response failed
                this.setState({
                    error_messages: ["Failed to get location types!"],
                    location_types_loading : false
                });
            }
        })
    }

    getStatesOrRegions(siteId){
        //set loading
        this.setState({ states_or_regions_loading : true });

        //get data from API
        fetch(getStateOrRegionUrl, {
            method : 'POST',
            body : JSON.stringify({
                site_id : siteId,
                secret_key : apiSettings.secret_key,
                token : apiSettings.token
            })
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(data.is_valid){
                        //response success
                        let stateOrRegionOptions = [];

                        data.data.map(stateOrRegion => {
                            stateOrRegionOptions.push({
                                label : stateOrRegion,
                                value : stateOrRegion
                            });

                            return true;
                        })

                        this.setState({
                            states_or_regions : stateOrRegionOptions,
                            state : { label: 'Select State/Region', value: '' }, //set default plaveholder
                            error_messages : [],
                            states_or_regions_loading : false
                        });
                    }else{
                        //response failed
                        this.setState({
                            error_messages: data.error_messages,
                            states_or_regions_loading : false
                        });
                    }
                });
            }
            else{
                //response failed
                this.setState({
                    error_messages: ["Failed to get states or regions!"],
                    states_or_regions_loading : false
                });
            }
        })
    }

    getLocationsName(siteId, locationType, stateOrRegion){
        //set loading
        this.setState({ location_names_loading : true });

        //get data from API
        fetch(getLocationNameUrl, {
            method : 'POST',
            body : JSON.stringify({
                site_id : siteId,
                location_type : locationType,
                state_region : stateOrRegion,
                secret_key : apiSettings.secret_key,
                token : apiSettings.token
            })
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(data.is_valid){
                        //response success
                        let locationNameOptions = [];

                        data.data.map(locationName => {
                            locationNameOptions.push({
                                label : locationName.name,
                                value : locationName.name
                            });

                            return true;
                        })

                        //check if store add "store not listed" option
                        if(locationType===2){ //store type
                            locationNameOptions.push({
                                label : storeNotListedLabel,
                                value : storeNotListedLabel
                            });

                            return true;
                        } 

                        this.setState({
                            location_names : locationNameOptions,
                            selected_dealer : { label: 'Select '+this.state.locationTypeName, value: '' },
                            error_messages : [],
                            location_names_loading : false
                        });
                    }else{
                        //response failed
                        this.setState({
                            error_messages: data.error_messages,
                            location_names_loading : false
                        });
                    }
                });
            }
            else{
                //response failed
                this.setState({
                    error_messages: ["Failed to get location names!"],
                    location_names_loading : false
                });
            }
        })
    }

    //enable / disable inputs
    enableInputs(isEnable){
        let inputs = document.getElementsByClassName("form-control");
        for(let i=0;i < inputs.length; i++){
            if(!isEnable){
                inputs[i].setAttribute("readonly", "readonly");
            }else{
                inputs[i].removeAttribute("readonly");
            }
        }
    }

    handleTextChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }

    handleLocationTypeChange(e){
        let locationTypeName = e.target.value;
        let locationTypeEnum = e.target.options[e.target.selectedIndex].getAttribute('data-type');
        let locationTypeShowStateRegion = e.target.options[e.target.selectedIndex].getAttribute('data-show-state-region');
        
        //check if work choosen
        if(locationTypeName!==''){
            //check if must other or not
            if(locationTypeEnum!=='5'){ //not other
                //check if bmsa and not needed show location name select
                if(this.props.site.site_path.toLowerCase()==='/bmsa' && locationTypeShowStateRegion === 'false'){
                    this.setState({
                        locationTypeEnum: locationTypeEnum,
                        locationTypeName: locationTypeName,
                        showStateOrRegionSelect: locationTypeShowStateRegion === 'true' ? true : false,
                        showLocationNameSelect: false,
                        locationTypeOtherSelected: false,
                        showUserInfoForm: true, //hide user form
                        selected_dealer: { label: locationTypeName, value: locationTypeName }, //reset selected dealer
                        dealer_name : locationTypeName
                    });
                }else{
                    this.setState({
                        locationTypeEnum: locationTypeEnum,
                        locationTypeName: locationTypeName,
                        showStateOrRegionSelect: locationTypeShowStateRegion === 'true' ? true : false,
                        showLocationNameSelect: locationTypeShowStateRegion === 'true' ? false : true,
                        locationTypeOtherSelected: false,
                        showUserInfoForm: false, //hide user form
                        selected_dealer: {} //reset selected dealer
                    }, () => {
                        //get location name if do not required state or region
                        if(!this.state.showStateOrRegionSelect){
                            this.getLocationsName(this.state.site_id, this.state.locationTypeEnum, "");
                        }
                    });
                }               
            }else{
                //other selected
                this.setState({
                    locationTypeEnum: locationTypeEnum,
                    locationTypeName: locationTypeName,
                    showStateOrRegionSelect: false,
                    showLocationNameSelect: false,
                    locationTypeOtherSelected: true,
                    showUserInfoForm: false, //hide user form
                    selected_dealer: {} //reset selected dealer
                });
            }
        }else{
            //please choose work type / location type
            //hide all
            this.setState({
                locationTypeName: '',
                showStateOrRegionSelect: false,
                showLocationNameSelect: false,
                locationTypeOtherSelected: false,
                showUserInfoForm: false, //hide user form
                selected_dealer: {} //reset selected dealer
            });
        }
    }

    handleStateOrRegionChange(e){
        let stateOrRegion = e.value; //get location state: NSW
        if(stateOrRegion!==''){
            this.setState({
                state : e, //set state or region
                selected_dealer: {} //reset selected dealer
            });

            //fetching data by params (location_name, location_state_or_region) result list of location
            this.getLocationsName(this.state.site_id, this.state.locationTypeEnum, stateOrRegion);
            this.setState({ showLocationNameSelect: true });
        }else{
            //no state/region selected
            this.setState({ 
                state : "",
                showLocationNameSelect: false,
                selected_dealer: {} //reset selected dealer
            });
        }
    }

    handleLocationNameChange = selectedOption => {
        let locationName = selectedOption.value;
        if(locationName!==''){
            let isStoreNotListed = false;
            //check if selected value is store not listed
            if(locationName===storeNotListedLabel){
                isStoreNotListed = true;
            }

            //choosed
            this.setState({
                selected_dealer: selectedOption,
                dealer_name : locationName,
                showUserInfoForm: true,
                isStoreNotListed : isStoreNotListed
            });
        }else{
            //not choosed
            this.setState({
                selected_dealer: {},
                dealer_name : '',
                showUserInfoForm: false
            });
        }
    }

    handleLocationOtherChange(e){
        this.setState({ dealer_name : e.target.value });
        if(e.target.value.length >= 3){
            this.setState({ showUserInfoForm : true });
        }else{
            this.setState({ showUserInfoForm : false });
        }
    }

    handleStoreNotListedChange(e){
        this.setState({ other_store_name : e.target.value });
    }

    handleSubmit(e){
        e.preventDefault();

        //get dealer name
        let dealerName = this.state.dealer_name;
        //check if from store not listed
        if(dealerName===storeNotListedLabel){
            dealerName = this.state.other_store_name;
        }

        //trim object
        let user = {
            site_id: this.state.site_id,
            location_type: this.state.locationTypeEnum,
            state: this.state.state.value,
            region: this.state.region,
            dealer_name: dealerName,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            phone_area: this.state.phone_area,
            phone_number: this.state.phone_number,
            email_address: this.state.email_address,
            confirm_email_address: this.state.confirm_email_address,
            password: this.state.password,
            confirm_password: this.state.confirm_password,
            notification_email: this.state.notification_email,
            notification_sms: this.state.notification_sms,
        };

        if(isDebug){
            console.log(user);
        }

        //check if show terms and condition
        let isValid = true;
        if(!this.state.show_terms_and_conditions){
            /* Validation */
            isValid = this.validateInput(user);
        }
 
        if(!isValid){
            //error exist
            this.setState({
                error_messages : ["There is an error, Please see form."]
            });
        }else{
            //check if user accept terms and conditions
            if(this.state.accept_terms_and_conditions){
                //signing up
                this.register(user);
            }else{
                //show terms and conditions forms
                this.setState({ show_terms_and_conditions : true });
            }
        }   
    }

    validateInput(user){
        let errorMessages = [];

        let otherStoreName = document.getElementsByName('other_store_name')[0];
        let firstName = document.getElementsByName('first_name')[0];
        let lastName = document.getElementsByName('last_name')[0];
        let phoneNumber = document.getElementsByName('phone_number')[0];
        let phoneArea = document.getElementsByName('phone_area')[0];
        let emailAddress = document.getElementsByName('email_address')[0];
        let confirmEmailAddress = document.getElementsByName('confirm_email_address')[0];
        let password = document.getElementsByName('password')[0];
        let confirmPassword = document.getElementsByName('confirm_password')[0];

        //validate other store name
        if(this.state.isStoreNotListed){
            if(user.dealer_name.length < 1 || user.dealer_name==null){
                this.showError(otherStoreName, true, 'Field is required');
                errorMessages.push(true);
            }else{
                this.showError(otherStoreName, false, '');
            }
        }

        //validate firstname
        if(user.first_name.length < 1 || user.first_name==null){
            this.showError(firstName, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(firstName, false, '');
        }

        //validate lastName
        if(user.last_name.length < 1 || user.last_name==null){
            this.showError(lastName, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(lastName, false, '');
        }

        //validate phoneNumber
        if((user.phone_number.length < 1 || user.phone_number==null) || (user.phone_area.length < 1 || user.phone_area==null)){
            this.showError(phoneNumber, true, 'Field is required');
            this.showError(phoneArea, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(phoneNumber, false, '');
            this.showError(phoneArea, false, '');
        }

        //validate email
        if(user.email_address.length < 1 || user.email_address==null){
            this.showError(emailAddress, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(emailAddress, false, '');
        }

        //validate confirm email
        if(user.confirm_email_address.length < 1 || user.confirm_email_address==null){
            this.showError(confirmEmailAddress, true, 'Field is required');
            errorMessages.push(true);
        }else{
            //check match
            if(user.confirm_email_address!==user.email_address){
                this.showError(confirmEmailAddress, true, 'Confirm Email do not match');
                errorMessages.push(true);
            }else{
                this.showError(confirmEmailAddress, false, '');
            }
        }

        //validate password
        if(user.password.length < 1 || user.password==null){
            this.showError(password, true, 'Field is required');
            errorMessages.push(true);
        }else{
            //check password length
            if(user.password.length < 4){
                this.showError(password, true, 'Password must be greater or equal to 4 characters');
                errorMessages.push(true);
            }else{
                this.showError(password, false, '');
            }
        }

        //validate confirm password
        if(user.confirm_password.length < 1 || user.confirm_password==null){
            this.showError(confirmPassword, true, 'Field is required');
            errorMessages.push(true);
        }else{
            //check match
            if(user.confirm_password!==user.password){
                this.showError(confirmPassword, true, 'Confirm Password do not match');
                errorMessages.push(true);
            }else{
                this.showError(confirmPassword, false, '');
            }
        }

        //validate notification method
        console.log(user.notification_email+" & "+user.notification_sms);
        if(user.notification_email===null && user.notification_sms===null){
            this.setState({
                notification_error : 'Field is required'
            });
            errorMessages.push(true);
        }else{
            this.setState({
                notification_error : ''
            });
        }

        //check error
        if(errorMessages.length > 0){
            return false;
        }else{
            //no error
            return true;
        }
    }
    
    showError(element, show, message){
        let parent = element.parentElement;
        let errorText = parent.querySelector('.text-error');
        if(show){
            element.classList.add('border-error');
            errorText.innerHTML = message;
        }else{
            //hide error
            element.classList.remove('border-error');
            errorText.innerHTML = "";
        }
    }

    register(user){
        //show loading
        this.setState({ is_loading: true });
        this.enableInputs(false);

        //request signup API
        //append secret key & token to body request
        let bodyRequest = Object.assign({}, user, {
            secret_key : apiSettings.secret_key,
            token : apiSettings.token
        });

        fetch(signUpUrl, {
            method: 'POST',
            body: JSON.stringify(bodyRequest)
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    if(isDebug){
                        console.log(data);
                    }
                    //check if valid
                    if(data.is_valid){    
                        this.setState({ is_success : true, show_terms_and_conditions : false });
                    }else{
                        let errorMessages = [];

                        //check if errormessages exist
                        if(data.error_messages.length > 0){
                            errorMessages = data.error_messages;
                        }else{
                            //error on message
                            errorMessages.push(data.message);
                        }  
                        
                        this.setState({ 
                            error_messages: errorMessages ,
                            show_terms_and_conditions : false,
                            accept_terms_and_conditions : false
                        });
                        this.enableInputs(true);

                        this.checkResponseError(errorMessages);
                    }
                });
            }
            else{
                //response failed
                this.setState({ 
                    error_messages: ["response status "+response.status],
                    show_terms_and_conditions : false,
                    accept_terms_and_conditions : false
                });
                this.enableInputs(true);
            }

            this.setState({ is_loading: false });
        })
    }

    checkResponseError(messages){
        //check if array or string
        if(Array.isArray(messages)){
            messages.map(message => {
                this.showErrorOn(message);

                return true;
            })
        }else{
            this.showErrorOn(messages);
        }
    }

    showErrorOn(message){
        if(message.toLowerCase().includes("email")){
            //show error on email
            this.showError(document.getElementsByName('email_address')[0], true, message);
        }else if(message.toLowerCase().includes("password")){
            //show error on email
            this.showError(document.getElementsByName('password')[0], true, message);
        }
    }

    setNotificationMethod(e){
        if(e.target.value==='email'){
            this.setState({
                notification_email: true,
                notification_sms : false
            });
        }else if(e.target.value==='sms'){
            this.setState({
                notification_email: false,
                notification_sms : true
            });
        }else if(e.target.value==='both'){
            this.setState({
                notification_email: true,
                notification_sms : true
            });
        }else{
            this.setState({
                notification_email: false,
                notification_sms : false
            });
        }
    }

    render(){
        return(
        <div>
            <div className="bg-content">
                <div className="container">
                    <form onSubmit={this.handleSubmit}>   
                        <div className="row justify-content-center">
                            { this.state.show_terms_and_conditions ? (
                                <div className="col-md-8 px-xs-0 terms-conditional">
                                    <TermsAndConditions />
                                    <div className="form-check form-check-inline agree-check">
                                        <input className="form-check-input" type="checkbox" id="agreement" name="agreement" onChange={ (e) => {
                                            this.setState({ accept_terms_and_conditions : e.target.checked });
                                        } } defaultChecked={ this.state.accept_terms_and_conditions }/><label htmlFor="agreement">I agree to the terms and conditions</label>
                                    </div>
                                    <div className="form-group text-center wrap-btn-step">
                                        { this.state.is_loading ? (
                                            <button type="button" className="btn btn-dark btn-step" disabled>Creating Account...</button>
                                        ) : (
                                            <button type="submit" className="btn btn-dark btn-step">Accept and Register</button>
                                        ) } 
                                    </div>
                                </div>
                            ) : (       
                                this.state.is_success ? <CheckEmail email_address={this.state.email_address} /> : (
                                <div className="col-md-8 col-lg-5 px-0">               
                                    <h1>Enter your details to register</h1>
                                    { this.state.showStoreSelect ? (
                                    <div className="form-group">
                                        { this.state.location_types_loading ? <Loading/> : (
                                            <select className="form-control form-regist arrow-select first-form" value={ this.state.locationTypeName } onChange={ this.handleLocationTypeChange } required>
                                                <option value="">Where do you work?</option>
                                                { this.state.location_types.map((locationType, key) => (
                                                    <option key={key} data-type={locationType.id} data-show-state-region={locationType.show_state_region} value={locationType.name}>{locationType.name}</option>
                                                )) }
                                            </select>
                                        ) }
                                    </div>
                                    ) : "" }
                                    { this.state.locationTypeOtherSelected ? (
                                        <div className="form-group">
                                            <input type="text" value={this.state.selected_dealer.value} onChange={this.handleLocationOtherChange} className="form-control form-regist" placeholder="Enter location" required/>
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            { this.state.showStateOrRegionSelect ? (
                                                     this.state.states_or_regions_loading ? <Loading/> : (
                                                        <Select
                                                            value={this.state.state}
                                                            onChange={this.handleStateOrRegionChange}
                                                            options={this.state.states_or_regions}
                                                            className="form-regist arrow-select"
                                                            required
                                                        />
                                                    )
                                            ) : "" }
                                            { this.state.showLocationNameSelect ? (
                                                 this.state.location_names_loading ? <Loading/> : (
                                                    <Select
                                                        value={this.state.selected_dealer}
                                                        onChange={this.handleLocationNameChange}
                                                        options={this.state.location_names}
                                                        className="form-regist arrow-select"
                                                        required
                                                    />
                                                )
                                            ) : "" }
                                        </div>
                                    ) }
                                    { this.state.showUserInfoForm ? (
                                    <div>
                                        {/* Check if store not listed is selected or not */}
                                        { this.state.isStoreNotListed ? (
                                            <div className="form-group">
                                                <input type="text" name="other_store_name" value={this.state.other_store_name} onChange={this.handleStoreNotListedChange} className="form-control form-regist" placeholder="Enter store here" maxLength="100"/>
                                                <small className="form-text text-error"></small>
                                            </div>
                                        ) : "" }
                                        
                                        <div className="form-group">
                                            <input type="text" name="first_name" value={this.state.first_name} onChange={this.handleTextChange} className="form-control form-regist" placeholder="First Name" maxLength="50"/>
                                            <small className="form-text text-error"></small>
                                        </div>
                                        <div className="form-group">
                                            <input type="text" name="last_name" value={this.state.last_name} onChange={this.handleTextChange} className="form-control form-regist" placeholder="Last Name" maxLength="50"/>
                                            <small className="form-text text-error"></small>
                                        </div>
                                        <div className="input-group form-phone">
                                            <input className="form-control code-phone" type="text" name="phone_area" value={this.state.phone_area} onChange={this.handleTextChange} placeholder="+61" maxLength="3" autoComplete="off"/>
                                            <input className="form-control no-phone" type="text" name="phone_number" value={this.state.phone_number} onChange={this.handleTextChange} placeholder="Mobile" maxLength="10" autoComplete="off"/>
                                            <small className="form-text text-error"></small>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" name="email_address" value={this.state.email_address} onChange={this.handleTextChange} className="form-control form-regist" placeholder="Email" maxLength="150"/>
                                            <small className="form-text text-error"></small>
                                        </div>
                                        <div className="form-group">
                                            <input type="email" name="confirm_email_address" value={this.state.confirm_email_address} onChange={this.handleTextChange} className="form-control form-regist" placeholder="Confirm Email" maxLength="150"/>
                                            <small className="form-text text-error"></small>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="password" value={this.state.password} onChange={this.handleTextChange} className="form-control form-regist" placeholder="Create Password" maxLength="50"/>
                                            <small className="form-text text-error"></small>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" name="confirm_password" value={this.state.confirm_password} onChange={this.handleTextChange} className="form-control form-regist" placeholder="Confirm Password" maxLength="50"/>
                                            <small className="form-text text-error"></small>
                                        </div>
                                        <div className="form-group notification" onChange={this.setNotificationMethod}>
                                            <label>Preferred notification method </label>
                                            <div className="group-notif d-flex  justify-content-between">
                                                <div className="form-check form-check-inline radio-btn">
                                                    <input className="form-check-input" type="radio" name="notification_method" id="inlineCheckbox1" value="email"/>
                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Email</label>
                                                </div>
                                                <div className="form-check form-check-inline radio-btn">
                                                    <input className="form-check-input" type="radio" name="notification_method" id="inlineCheckbox2" value="sms" />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">SMS</label>
                                                </div>
                                                <div className="form-check form-check-inline radio-btn">
                                                    <input className="form-check-input" type="radio" name="notification_method" id="inlineCheckbox3" value="both" />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox3">Both</label>
                                                </div>
                                            </div>
                                            <small className="form-text text-error">{ this.state.notification_error }</small>
                                        </div>
                                        <div className="form-group wrap-btn-step text-center">
                                            { this.state.is_loading ? (
                                                <button type="button" className="btn btn-dark btn-step" disabled>Creating Account...</button>
                                            ) : (
                                                <button type="submit" className="btn btn-dark btn-step">Next Step</button>
                                            ) }    
                                        </div>
                                        <div className="footer-text">
                                            <div className="register">
                                                <span>View Bridgestone’s </span><a href="#!" to="#!" data-toggle="modal" data-target="#privacyPolicyModal" className="text-red">privacy policy</a>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ""}
                                </div>          
                                )
                            )}
                        </div>
                    </form>  
                    { this.state.locationTypeName==='' ? (
                        <div className="row justify-content-center">
                            <div className="footer-text">
                                <div className="register register-page">
                                    <span>Already have an account? </span><Link to={`${this.props.site.site_path}/login`} >Log in here</Link>
                                </div>
                            </div>
                        </div>
                     ) : ''}
                </div>
            </div>
            <PrivacyPolicy />
        </div>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer
});

export default connect(mapStateToProps, {
    login
})(Signup);