import React, { Component } from 'react';
import { connect } from 'react-redux';

//actions
import { getUserInfo } from '../../redux/actions/userAction';

class RoundInfo extends Component{
    constructor(){
        super();
        this.state = {
            progress_of_round: "",
            complete_round: 0,
            correct_answer: "",
            total_time : "",
            is_loading: false
        }
    }

    componentDidUpdate(newProps){
        //check if props changes
        if(newProps.user!==this.props.user){
            //user changed
            //get user info
            this.getUserInfo();
        }
    }

    componentDidMount(){
        //get user info
        this.getUserInfo();
    }

    getUserInfo(){
        this.setState({ is_loading : true });

        this.props.getUserInfo((res) => {
            if(res.is_valid){
                this.setState({
                    progress_of_round: res.data.progress_of_round,
                    complete_round: res.data.complete_round > 0 ? res.data.complete_round : '-',
                    correct_answer: res.data.correct_answer !== '0/0' ? this.changeCorrectAnswerDisplay(res.data.correct_answer) : '-',
                    total_time: res.data.total_time !== '00:00:00' ? res.data.total_time : '-',
                    is_loading: false
                });
            }else{
                this.setState({
                    is_loading: false
                });
            }
        });
    }

    changeCorrectAnswerDisplay(correct_answer){
        let list = correct_answer.split('/');
        return list[0];
    }

    render(){
        return(
            <div className="row justify-content-center">
                <div className="col-lg-3 wrap-text-progress">
                    <span className="title">
                    { this.state.progress_of_round!=='' ? (
                        <span >
                            <b>Progress as of Round { this.state.progress_of_round }</b>
                        </span>
                    ) : ''}
                    </span>
                    
                    <span className="caption">
                        Completed Rounds, Score and Total Time will update once the next round is open.
                    </span>
                </div>
                <div className="col-lg-6 table-box-round">
                    <div className="dashboard-round">
                        <div className="dashboard-table">
                            <div className="wrap-box">
                                <div className="box first-box">
                                    <span className="text-round">Completed Rounds</span>
                                    <span className="number-round">{ this.state.is_loading ? <LoadingHome /> : this.state.complete_round }</span>
                                </div>
                                <div className="box second-box">
                                    <span className="text-round">Score</span>
                                    <span className="number-round">{ this.state.is_loading ? <LoadingHome /> : this.state.correct_answer }</span>
                                </div>
                                <div className="box">
                                    <span className="text-round">Total Time</span>
                                    <span className="number-round">{ this.state.is_loading ? <LoadingHome /> : this.state.total_time }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class LoadingHome extends React.Component {
    render() {
       return (
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
       );
    }
 }

const mapStateToProps = state => ({
    user : state.userReducer.user
});

export default connect(mapStateToProps, {
    getUserInfo
})(RoundInfo);