import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiUrl, apiSettings, isDebug, redirectToMobileApp } from '../../config';
import {  Link } from "react-router-dom";

//component
import Loading from '../Loading';

//url
const checkResetPasswordUrl = apiUrl + 'User/CheckResetPassword';
const resetPasswordUrl = apiUrl + 'User/ResetPassword';

class ResetPassword extends Component{
    constructor(){
        super();

        this.state = {
            site_id: 0,
            email_address: '',
            fp_token: '',
            password: '',
            confirm_password: '',
            message: '',
            is_loading: false,
            is_submit_loading: false,
            is_valid: false,
            is_success: false,
            is_alert_succes:true,
            is_alert_failed:true
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validatingEmailAndToken = this.validatingEmailAndToken.bind(this);
    }

    componentDidMount(){
        //set email and fp token to state
        this.setState({
            site_id: this.props.site.id,
            email_address : this.props.match.params.email,
            fp_token : this.props.match.params.token
        }, () => {
            if(redirectToMobileApp){
                //show confirmation
                var redirectConfirmation = window.confirm("Open in mobile app?");
                if (redirectConfirmation === true) {
                    window.location.href = "bsolympic://bridgestoneolympic"+this.props.site.site_path+"/reset-password/"+this.props.match.params.email+"/"+this.props.match.params.token
                } else {
                    this.validatingEmailAndToken();
                }
            }else{
                this.validatingEmailAndToken();
            }  
        });
    }

    handleTextChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }

    validatingEmailAndToken(){
        //set loading
        this.setState({
            is_loading: true
        }); 

        //get data from API
        let body = {
            site_id : this.state.site_id,
            email_address : this.state.email_address,
            fp_token : this.state.fp_token,
            secret_key : apiSettings.secret_key,
            token : apiSettings.token
        };

        if(isDebug){
            console.log(body);
        }

        fetch(checkResetPasswordUrl, {
            method : 'POST',
            body : JSON.stringify(body)
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(isDebug){
                        console.log(data);
                    }
                    if(data.is_valid){
                        //response success
                        this.setState({
                            message : ''
                        });
                    }else{
                        //response failed
                        this.setState({
                            message: data.message
                        });
                    }

                    //disable loading
                    this.setState({
                        is_loading: false,
                        is_valid: data.is_valid
                    }); 
                });
            }
            else{
                //response failed
                this.setState({
                    message: "Failed to get response from server!",
                    is_loading : false,
                    is_valid : false
                });
            }
        });
    }

    handleSubmit(e){
        e.preventDefault();

        //submit
        let body = {
            email_address : this.state.email_address,
            fp_token : this.state.fp_token,
            password : this.state.password,
            confirm_password : this.state.confirm_password,
            secret_key : apiSettings.secret_key,
            token : apiSettings.token
        };

        let isValid = false;
        /* Validation */
        isValid = this.validateInput(body);

        if(isValid){
            this.resetPassword(body);
        }
    }

    validateInput(user){
        let errorMessages = [];

        let password = document.getElementsByName('password')[0];
        let confirmPassword = document.getElementsByName('confirm_password')[0];

        //validate password
        if(user.password.length < 1 || user.password==null){
            this.showError(password, true, 'Field is required');
            errorMessages.push(true);
        }else{
            //check password length
            if(user.password.length < 4){
                this.showError(password, true, 'Password must be greater or equal to 4 characters');
                errorMessages.push(true);
            }else{
                this.showError(password, false, '');
            }
        }

        //validate confirm password
        if(user.confirm_password.length < 1 || user.confirm_password==null){
            this.showError(confirmPassword, true, 'Field is required');
            errorMessages.push(true);
        }else{
            //check match
            if(user.confirm_password!==user.password){
                this.showError(confirmPassword, true, 'Confirm Password do not match');
                errorMessages.push(true);
            }else{
                this.showError(confirmPassword, false, '');
            }
        }

        //check error
        if(errorMessages.length > 0){
            return false;
        }else{
            //no error
            return true;
        }
    }
    
    showError(element, show, message){
        let parent = element.parentElement;
        if(isDebug){
            console.log(parent);
        }
        let errorText = parent.querySelector('.text-error');
        if(show){
            element.classList.add('border-error');
            errorText.innerHTML = message;
        }else{
            //hide error
            element.classList.remove('border-error');
            errorText.innerHTML = "";
        }
    }

    resetPassword(body){
        //set loading
        this.setState({
            is_submit_loading: true,
            is_alert:true
        }); 

        //send data to API
        fetch(resetPasswordUrl, {
            method : 'POST',
            body : JSON.stringify(body)
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(isDebug){
                        console.log(data);
                    }
                    if(data.is_valid){
                        //response success
                        this.setState({
                            message : '',
                            is_alert_succes:true
                        });
                    }else{
                        //response failed
                        this.setState({
                            message: data.message,
                            is_alert_failed:true
                        });
                    }

                    //disable loading
                    this.setState({
                        is_submit_loading: false,
                        is_success: data.is_valid
                    }); 
                });
            }
            else{
                //response failed
                this.setState({
                    message: "Failed to get response from server!",
                    is_submit_loading : false,
                    is_success : false,
                    is_alert_failed:true
                });
            }
        });
    }

    removeAlert = () => {
        this.setState({
            is_alert_succes:false,
            is_alert_failed:false
        })
    }

    render(){
        const Messages = () => {
            return(
                <div>
                    {
                        this.state.is_success ? (
                            this.state.is_alert_succes ? 
                            <div  className="alert alert-success alert-ui alert-dismissible fade show">
                                Your password has been updated. Please click <Link to={`${this.props.site.site_path}/login`} >here</Link> to login.
                                <button type="button" className="close" onClick={() => this.removeAlert()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> : "" 
                        ) : (
                            this.state.message!=='' ? 
                            (
                                this.state.is_alert_failed ? 
                                <div  className="alert alert-danger alert-ui alert-dismissible fade show">
                                    { this.state.message }
                                    <button type="button" className="close" onClick={() => this.removeAlert()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div> : "" 
                            ) : ''
                        )
                    }
                </div>
            )
        }

        return(     
            <div className="bg-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 login-page px-0">
                            { this.state.is_loading ? <Loading /> : (
                                <div>
                                    <Messages />
                                    { this.state.is_valid ? (
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="form-group">
                                                <input type="password" name="password" value={this.state.password} onChange={this.handleTextChange} className="form-control form-regist mt-0" placeholder="Password"/>
                                                <small className="form-text text-error"></small>
                                            </div>   
                                            <div className="form-group form-password">
                                                <input type="password" name="confirm_password" value={this.state.confirm_password} onChange={this.handleTextChange} className="form-control form-regist" placeholder="Confirm Password"/>
                                                <small className="form-text text-error"></small>
                                            </div>      
                                            <div className="form-group text-center wrap-btn-step"> 
                                                { this.state.is_submit_loading ? (
                                                    <button type="button" className="btn btn-dark btn-step" disabled>Submitting...</button>
                                                ) : (
                                                    <button type="submit" className="btn btn-dark btn-step">Submit</button>
                                                )}
                                            </div>
                                        </form>
                                    ) : '' }
                                </div>
                            ) }
                        </div>              
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer
});

export default connect(mapStateToProps, {
})(ResetPassword);