import { LOGIN, LOGOUT } from './actionTypes';
import { apiUrl, apiSettings, isDebug } from '../../config';
import { getSiteFromUrl } from './siteAction';
import { USER_KEY } from '../../constants/userLocalStorageKeys';

const getLoginUrl = apiUrl+'user/login';
const getLogoutUrl = apiUrl+'user/logout';
const getUserInfoUrl = apiUrl+'User/Info';
const getCheckUserLoginSiteUrl = apiUrl+'User/CheckUserLoginSite';
const getUserDetailsUrl = apiUrl+'User/GetDetails';

/*
INIT SITE:
- get host first ()
- get site from domain name
*/
export const login = (user, callback) => dispatch => {
    //get site
    let site = getSiteFromUrl();

    //append secret key & token to body request
    let bodyRequest = Object.assign({}, user, {
        secret_key : apiSettings.secret_key,
        token : apiSettings.token
    });

    fetch(getLoginUrl, {
        method: 'POST',
        body: JSON.stringify(bodyRequest)
    }).then((response) => {
        //check response status
        if(response.status===200){
            //response oke, get response data
            response.json().then(data => {
                //console.log(data);             
                callback(data);

                //check if data is valid
                if(data.is_valid){
                    // user signed up success
                    localStorage.setItem(USER_KEY+'_'+site.country_path, JSON.stringify(data.data));
                    //trigger state change
                    dispatch({
                        type: LOGIN,
                        payload: data.data
                    });
                }
            });
        }
        else{
            //response failed
            callback({
                message: "response status error!",
                error_messages: ["response status error!"],
                is_valid: false
            });
        }
    }).catch((error) => {
        if(isDebug){
            console.log(error);
        }
        callback({
            message: error,
            error_messages : ["failed to get response from server!"],
            is_valid: false
        });
    });
}

//check if user login or not get from local storage
export const initAuthentication = (siteId, callback) => dispatch => {
    //get site
    let site = getSiteFromUrl();

    let user = JSON.parse(localStorage.getItem(USER_KEY+'_'+site.country_path));
    if(user!==null){
        //check if user on current site
        if(user.site_id===siteId){
            if(isDebug){
                console.log(user);
            }
            //check with API
            checkUserLoginSite(siteId, (res) => {
                if(isDebug){
                    console.log(res);
                }
                if(res.is_valid){
                    dispatch({
                        type: LOGIN,
                        payload: user
                    });

                    callback(true);
                }else{
                    //check if user inactive
                    if(res.message.toLowerCase().includes('user is inactive')){
                        callback({
                            is_valid : false,
                            logout : true
                        });
                    }else{
                        //user access token is invalid
                        callback(false);
                    }
                }
            });
        }else{
            //user login on other site
            callback(false);
        }       
    }else{
        //user not found
        callback(false);
    }
}

export const getAccessToken = () => {
    let accessToken = null;

    //get site
    let site = getSiteFromUrl();

    let user = JSON.parse(localStorage.getItem(USER_KEY+'_'+site.country_path));
    if(user!=null){
        accessToken = user.access_token;
    }

    return accessToken;
}

export const logout = (callback) => dispatch => {
    //get access token
    let accessToken = getAccessToken();
    fetch(getLogoutUrl, {
        method: 'POST',
        body: JSON.stringify({
            access_token: accessToken,
            secret_key : apiSettings.secret_key,
            token : apiSettings.token
        })
    }).then((response) => {
        //check response status
        if(response.status===200){
            //response oke, get response data
            response.json().then(data => {
                console.log(data);
                //remove from local
                localStorage.removeItem(USER_KEY);

                callback(true);

                dispatch({
                    type: LOGOUT
                });
            });
        }
        else{
            //response failed
            callback(false);
        }
    }).catch((error) => {
        console.log(error);
        callback(false);
    });
}

/*
Get user info:
- complete round
- total answer
- total time
*/
export const getUserInfo = (callback) => dispatch => {
    //append secret key & token to body request
    let bodyRequest = {
        access_token : getAccessToken(),
        secret_key : apiSettings.secret_key,
        token : apiSettings.token
    };

    fetch(getUserInfoUrl, {
        method: 'POST',
        body: JSON.stringify(bodyRequest)
    }).then((response) => {
        //check response status
        if(response.status===200){
            //response oke, get response data
            response.json().then(data => {
                if(isDebug){
                    console.log(data);
                }
                callback(data);
            });
        }
        else{
            //response failed
            callback({
                message: "response status error!",
                error_messages: ["response status error!"],
                is_valid: false
            });
        }
    }).catch((error) => {
        callback({
            message: error,
            error_messages : ["failed to get response from server!"],
            is_valid: false
        });
    });
}

/*
Get user info:
- complete round
- total answer
- total time
*/
export const checkUserLoginSite = (siteId, callback) => {
    //append secret key & token to body request
    let bodyRequest = {
        access_token : getAccessToken(),
        site_id : siteId,
        secret_key : apiSettings.secret_key,
        token : apiSettings.token
    };

    fetch(getCheckUserLoginSiteUrl, {
        method: 'POST',
        body: JSON.stringify(bodyRequest)
    }).then((response) => {
        //check response status
        if(response.status===200){
            //response oke, get response data
            response.json().then(data => {
                callback(data);
            });
        }
        else{
            //response failed
            callback({
                message: "response status error!",
                is_valid: false
            });
        }
    }).catch((error) => {
        callback({
            message: error,
            is_valid: false
        });
    });
}

/*
Get user details:
*/
export const getUserDetails = (callback) => {
    //append secret key & token to body request
    let bodyRequest = {
        access_token : getAccessToken(),
        secret_key : apiSettings.secret_key,
        token : apiSettings.token
    };

    fetch(getUserDetailsUrl, {
        method: 'POST',
        body: JSON.stringify(bodyRequest)
    }).then((response) => {
        //check response status
        if(response.status===200){
            //response oke, get response data
            response.json().then(data => {
                callback(data);
            });
        }
        else{
            //response failed
            callback({
                message: "response status error!",
                is_valid: false,
                data : {}
            });
        }
    }).catch((error) => {
        callback({
            message: error,
            data : {},
            is_valid: false
        });
    });
}