import { GET_SITE, SET_SITE } from '../actions/actionTypes';
import { siteModel } from '../../models/siteModel';

//init default site model
const initialState = siteModel;

export default (state = initialState, action) => {
    switch(action.type){
        case GET_SITE:
            return state;
        case SET_SITE:
            return Object.assign(
                {},
                state,
                action.payload
            );
        default:
            return (state);
    }
}