import { apiUrl, apiSettings } from '../config';

//url
const getContentItemsUrl = apiUrl + 'content/GetContentItem';

export default {
    getContenItem : (name, siteId, callback) => {
        //fetch data
        fetch(getContentItemsUrl, {
            method : 'POST',
            body : JSON.stringify({
                site_id : siteId,
                secret_key : apiSettings.secret_key,
                token : apiSettings.token,
                name : name
            })
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    callback(data);                   
                });
            }
            else{
                //response failed
                callback({
                    is_valid: false,
                    message : 'failed to get content item body'
                });
            }
        }).catch(err => {
            //response failed
            callback({
                is_valid: false,
                message : err.message
            });
        })
    }
}