import React, { Component } from 'react';
import { connect } from 'react-redux';
import contentItemNames from '../constants/contentItemNames';
import apiHelper from '../helpers/apiHelper';

//component
import Loading from './Loading';

class TermsAndConditions extends Component{
    constructor(){
        super();

        this.state = {
            error_message : '',
            is_loading : false,
            body : ''
        }

        this.getTermsAndConditions = this.getTermsAndConditions.bind(this);
    }

    componentWillMount(){
        this.getTermsAndConditions(this.props.site.id);
    }

    getTermsAndConditions(siteId){
        //set loading
        this.setState({
            is_loading : true
        });

        apiHelper.getContenItem(contentItemNames.TERMS_AND_CONDITIONS, siteId, (res) => {
            //check if valid
            if(res.is_valid){
                //response success
                this.setState({
                    body : res.data.body,
                    error_message : '',
                    is_loading : false
                });
            }else{
                //response failed
                this.setState({
                    error_message: res.message,
                    is_loading : false
                });
            }
        });
    }

    renderBodyContents = () => {
        return {__html: this.state.body}
    }

    render(){
        return(
            <React.Fragment>
                { this.state.is_loading ? <Loading /> : (
                    <div dangerouslySetInnerHTML={this.renderBodyContents()}></div>
                ) }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer
})

export default connect(mapStateToProps, {

})(TermsAndConditions);