/* Lib */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import IdleTimer from 'react-idle-timer'

//actions
import { initSite } from '../src/redux/actions/siteAction';
import { initAuthentication, checkUserLoginSite, logout } from '../src/redux/actions/userAction';

/* Components */
import Header from './components/Header';
import Footer from './components/Footer';

import Timezone from './components/Timezone';
import Home from './components/Home';
import Round from './components/round/Round';
import Account from './components/user/Account';
import Login from './components/user/Login';
import Signup from './components/user/Signup';
import LogoutModal from './components/user/LogoutModal';
import ForgotPassword from './components/user/ForgotPassword';
import ComingSoon from './components/ComingSoon';
import Activate from './components/user/Activate';
import PageNotFound from './components/PageNotFound';
import Loading from './components/Loading';
import ResetPassword from './components/user/ResetPassword';
import { isDebug, sessionTimeout } from './config';
/* Components */

// Call it once in your app. At the root of your app is the best place
toast.configure();
// Check browser
// Safari 3.0+ "[object HTMLElementConstructor]" 

const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
// Internet Explorer 6-11
const isIE = /*@cc_on!@*/false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;
var root = document.getElementsByTagName( 'html' )[0]; 
if(isIE){
  root.setAttribute( 'class', 'ie' );
} else if(isEdge){
  root.setAttribute( 'class', 'edge' );
} else if(isSafari){
  root.setAttribute( 'class', 'safari' );
}

class App extends Component {
  constructor(){
    super();
    this.state = {
      loading: true,
      sitePath: '',
      siteValid: null,
      activeClass: true
    };

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  componentDidMount(){
    
      window.addEventListener('resize',  () => {
        window.onresize = function(event) {
          var parent = document.querySelector('.content-page');
          if(parent !== null){
          var checkChild = parent.querySelector('.bg-timer ');

            var widthWindow = document.body.clientWidth;
            if(checkChild != null) {
              if(widthWindow < 991){
                var heightQuestion = document.getElementsByClassName("wrap-title")[0].clientHeight;
                var maginTopAnswerTable = heightQuestion + 'px';
                document.getElementsByClassName("answer-table")[0].style.marginTop= maginTopAnswerTable;
                document.getElementsByClassName("bg-timer")[0].setAttribute('class', 'bg-timer fixed-time');
                document.getElementsByClassName("wrap-title")[0].setAttribute('class', 'wrap-title fixed-title  page-shadow');
                document.getElementsByClassName("answer-table")[0].setAttribute('class', 'answer-table mobile-top');
              } else {
                document.getElementsByClassName("wrap-title")[0].setAttribute('class', 'col-lg-9 wrap-title');
                document.getElementsByClassName("answer-table")[0].style.marginTop= '0px';
                document.getElementsByClassName("answer-table")[0].setAttribute('class', 'answer-table');
                document.getElementsByClassName("bg-timer")[0].setAttribute('class', 'bg-timer fixed-time page-shadow');
              }
              
            }

          }
          
          
        };
      });
    
      window.addEventListener('scroll', () => {
        var parent = document.querySelector('.content-page');
        if(parent !== null){
          var checkChild = parent.querySelector('.bg-timer ');
        if(window.scrollY > 0){
            var widthWindow = document.body.clientWidth;
            document.getElementsByClassName("logo")[0].setAttribute('class', 'logo page-shadow');
            if(checkChild != null) {
              if(widthWindow < 991){
                //console.log('Less than 576');
                document.getElementsByClassName("bg-timer")[0].setAttribute('class', 'bg-timer fixed-time');
                document.getElementsByClassName("wrap-title")[0].setAttribute('class', 'col-lg-9 wrap-title fixed-title  page-shadow');
                var heightQuestion = document.getElementsByClassName("wrap-title")[0].clientHeight;
                var maginTopAnswerTable = heightQuestion + 'px';
                if (document.getElementsByClassName("answer-table")[0] !== undefined){
                  document.getElementsByClassName("answer-table")[0].style.marginTop= maginTopAnswerTable;
                }
              } else {
                document.getElementsByClassName("wrap-title")[0].setAttribute('class', 'col-lg-9 wrap-title');
                document.getElementsByClassName("answer-table")[0].setAttribute('class', 'answer-table');
                document.getElementsByClassName("bg-timer")[0].setAttribute('class', 'bg-timer fixed-time page-shadow');
              }
            }
        } else if(window.scrollY === 0) {
          document.getElementsByClassName("logo")[0].setAttribute('class', 'logo');
          if (document.getElementsByClassName("wrap-title")[0] !== undefined){
            document.getElementsByClassName("wrap-title")[0].setAttribute('class', 'col-lg-9 wrap-title desktop-wrap-title');
            if (document.getElementsByClassName("answer-table")[0] !== undefined){
              document.getElementsByClassName("answer-table")[0].style.marginTop= '0px';
            }
            
          }
          if(checkChild != null) {
            document.getElementsByClassName("bg-timer")[0].setAttribute('class', 'bg-timer fixed-time');
          }
        }
      }
      });
  }

  componentWillMount(){
    //init site
    this.props.initSite((res) => {
      this.setState({ siteValid: res.is_valid, sitePath: res.country },
      () => {
        //site is valid
        if(res.is_valid){
          //get local authentication from localstorage
          this.props.initAuthentication(this.props.siteId, (is_authenticated) => {
            if(typeof is_authenticated.logout !== 'undefined'){
              //logout
            }else{
              if(is_authenticated){
                //authenticated
              }
            }
            this.setState({ loading: false });
          });  
        }else{
          //site invalid
          this.setState({ loading: false });
        }
      });
    });
  }

  render() {
    /*
    Private route: user must be logged in
    */
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        //check if authenticated or not
        this.props.is_authenticated
          ? 
          //AUTHENTICATED -> check if route available on full site
          ( rest.on_full_site ? ( this.props.fullSite ? <Component {...props} /> : <Redirect to={`${this.props.sitePath}/coming-soon`} /> ) : <Component {...props} /> )
          //NOT AUTHENTICATED
          : (this.props.fullSite ? <Redirect to={`${this.props.sitePath}/login`} /> : <Redirect to={`${this.props.sitePath}/signup`} />)
      )} />
    )

    /*
    Private route: user must not be logged in
    */
    const UnauthorizedRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        //check if authenticated or not
        !this.props.is_authenticated
          ? 
          //NOT AUTHENTICATED -> check if route available on full site
          ( rest.on_full_site ? ( this.props.fullSite ? <Component {...props} /> : <Redirect to={`${this.props.sitePath}/coming-soon`} /> ) : <Component {...props} /> )
          //NOT AUTHENTICATED
          : <Redirect to={`${this.props.sitePath}/`} />
      )} />
    )

    /*
    Coming Soon: only for authenticated coming soon
    */
   const ComingSoonRoute = ({ component: Component, ...rest }) => (
      <Route {...rest} render={(props) => (
        !this.props.fullSite ? (this.props.is_authenticated ? <Component {...props} /> : <Redirect to={`${this.props.sitePath}/login`} />) : <Redirect to={`${this.props.sitePath}/`} />
      )} />
    )

    const SiteInvalid = () => {
      return (
        <div className="bg-content">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8 px-0 text-center thanks">
                        <h1 className="mb-6">Invalid Site</h1>
                    </div>
                </div>
            </div>
        </div>
      );
    };

    const App = (routeObj) => {
      return(
        <div>  
          { 
            this.state.loading ? <Loading/> : 
              //check if site is valid / not
              !this.state.siteValid ? <div><Header/> <SiteInvalid /> <Footer/> </div> : 
              (
                <div>
                  <Header/> 
                  <div className="content-page">
                    <Switch>
                      <Route path={`${routeObj.match.url}/timezone`} exact component={ Timezone } />   
                      <PrivateRoute path={`${routeObj.match.url}/`} exact component={ Home } on_full_site={true}/>
                      <PrivateRoute path={`${routeObj.match.url}/round`} exact component={ Round } on_full_site={true}/>
                      <PrivateRoute path={`${routeObj.match.url}/account`} exact component={ Account } on_full_site={true}/>
                      <UnauthorizedRoute path={`${routeObj.match.url}/login`} component={ Login } on_full_site={false}/>
                      <UnauthorizedRoute path={`${routeObj.match.url}/signup`} component={ Signup } on_full_site={false}/>
                      <UnauthorizedRoute path={`${routeObj.match.url}/forgot-password`} component={ ForgotPassword }/>
                      <ComingSoonRoute path={`${routeObj.match.url}/coming-soon`} component={ComingSoon}/>
                      <UnauthorizedRoute path={`${routeObj.match.url}/activate/:email/:token`} component={Activate}/>
                      <UnauthorizedRoute path={`${routeObj.match.url}/reset-password/:email/:token`} component={ResetPassword}/>

                      <Route path={`${routeObj.match.url}/css`} render={() => {

                          var links = document.getElementsByTagName("link");
                          for (var i = 0; i < links.length; i++) {
                            if(links[i].href.includes('static/css/main')){
                              return (<div><input type="text" value={links[i].href} id="main_css"/></div>)
                            }
                          }
                        }
                      }/>
                      <Route component={PageNotFound} />    
                    </Switch>
                  </div>
                  <Footer/>
                  <Route path={`${routeObj.match.url}/`} component={LogoutModal}/>
                </div>
              )
          }
        </div>
      );
    }

    return (
      <Router>
        
        {/* User Idle for 15 minutes */}
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={sessionTimeout} />

        <Route path={ this.state.sitePath } component={App} />
      </Router>
    );
  }

  //user actions
  _onAction(e) {
    if(isDebug){
      console.log('user did something', e)
    }
  }
 
  //user is active
  _onActive(e) {
    if(isDebug){
      console.log('user is active', e)
      console.log('time remaining', this.idleTimer.getRemainingTime())
    }
  }
 
  //user on idle for period of time (15)
  _onIdle(e) {
    //user has idle
    if(isDebug){
      console.log('user idle', e)
    }
    //is login
    if(this.props.is_authenticated){
      //logout
      this.props.logout(() => {

      });
    }
  }
}

const mapStateToProps = state => ({
  is_authenticated : state.userReducer.is_authenticated,
  siteId : state.siteReducer.id,
  sitePath : state.siteReducer.site_path,
  fullSite : state.siteReducer.full_site
});

export default connect(mapStateToProps, {
  initSite,
  initAuthentication,
  checkUserLoginSite,
  logout
})(App);