import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class RoundResult extends Component{
    getTimeSpan(elapsed){ // 754567(ms) -> "12:34.567"
        var m = String(Math.floor(elapsed/1000/60)+100).substring(1);
        var s = String(Math.floor((elapsed%(1000*60))/1000)+100).substring(1);
        var ms = String(elapsed % 1000 + 1000).substring(1);
        return m+":"+s+"."+ms;
    }

    render(){
        let answers = this.props.answers;
        let elapsedTime = this.props.elapsedTime;
        let round = this.props.round;
        let questions = round.questions;

        const Answers = () => {
            return(
                <div>
                    { questions.map((question, key) => (
                        <div key={key}>
                            Question { key+1 }: { answers.filter(answer => (answer.id===question.id)).map((answer, key2) => {
                                if(answer!==null){
                                    return (<span key={key2}>{answer.answer}</span>);
                                }else{
                                    return (<span key={key2}>-</span>);
                                }
                            }) }
                        </div>
                    )) }
                </div>
            );
        }

        return(
            <div className="container body-round">
                <div className="row justify-content-center">
                    <div className="col-lg-9 wrap-title">
                        <h3 className="title text-center">Round { round.name } complete.</h3>
                        <h3 className="title text-center">
                            You answered as follows: <br />
                            <Answers />
                        </h3>
                        <h3 className="title-time">
                            Your time was: <br />
                            { this.getTimeSpan(elapsedTime) }
                        </h3>
                    </div>
                    <div className="col-lg-3 wrap-title row justify-content-center thanks-participate">
                        <div className="col-lg-7">
                            <h3 className="title text-center">Thank you for participating in Round {round.name}.</h3>
                            <h3 className="title text-center text-complete">Completed Rounds, Score and Total Time will be updated on the dashboard once the next round opens.</h3>
                            <span className="return-dashboard"><Link to={`${this.props.sitePath}/`} >Return to dashboard</Link></span>
                        </div>
                    </div>
                    {/* <div className="col-lg-9">
                        <div className="row justify-content-center">
                            <Link className="btn btn-dark btn-question" to={`${this.props.sitePath}/`}>Close Window</Link>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sitePath : state.siteReducer.site_path
});

export default connect(mapStateToProps, {
})(RoundResult);