//debug mode(show console log when true)
export const isDebug = false;

//session timeout
const minute = 1000 * 60
export const sessionTimeout = minute * 15; //on minutes

//full site functional
export const isFullSiteFunctional = true;

//full site functional
export const redirectToMobileApp = false;

//URLs
export const tempSiteUrl = "bschallenge.jaydapps.com";
export const liveSiteUrl = "thebridgestonechallenge.com";

/*================ staging or live site ================*/ 
const isLive = false; //change this to set use staging or live
let apiSettingObj = {};
let baseUrlString = '';
if (isLive) {
    //check if temp or live
    const host = window.location.hostname;
    if(host.toLowerCase().includes(tempSiteUrl)){
        //temp
        baseUrlString = "http://cms-bschallenge.jaydapps.com/";
    }else{
        //live
        baseUrlString = "https://cms.thebridgestonechallenge.com/";
    }

    apiSettingObj = {
        secret_key : '921978916878494934',
        token : '64041fdc-42b4-4399-bf95-226621ca9b5a57aee323-2aa1-4cb5-975b-58eed922effd'
    }
} else {
    //staging
    baseUrlString = "https://bsolympiccms.stage02.jaydapps.com/";
    apiSettingObj = {
        secret_key : '636978916878494934',
        token : '27abb44c-f151-4cd3-b75e-8629791b6139cd42bea6-cfcc-472f-8748-afea7c094776'
    }
}
/*================ staging or live site ================*/ 

//set Base URL
export const baseUrl = baseUrlString;

//set API Url
export const apiUrl = baseUrl + "api/";

//set API Settings
export const apiSettings = apiSettingObj;