import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { apiUrl, apiSettings, isDebug } from '../config';
import moment from 'moment';

//components
import RoundInfo from './user/RoundInfo';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Prize from './prize/Prize';
import RoundWinner from './round/RoundWinner';
import StartRoundConfirmationModal from './round/StartRoundConfirmationModal';

//actions
import { setRound } from './../redux/actions/roundAction';
import OneAttemptUsedModal from './round/OneAttemptUsedModal';

//url
const getCurrentRoundUrl = apiUrl + 'Round/GetCurrentRound';

class Home extends Component{
    constructor(){
        super();

        this.state = {
            round : {},
            ongoing : false,
            message : '',
            currentCloseRoundDate : '',
            isValidToStart : false,
            loading_question : false
        }
        
        this.getRound = this.getRound.bind(this);
    }

    componentDidMount(){
        this.getRound();

    }

    getRound(){
        //set loading
        this.setState({ loading_question : true });

        //get data from API
        fetch(getCurrentRoundUrl, {
            method : 'POST',
            body : JSON.stringify({
                access_token : this.props.user.access_token,
                secret_key : apiSettings.secret_key,
                token : apiSettings.token
            })
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    if(isDebug){
                        console.log(data);
                    }
                    
                    //check if valid
                    if(data.is_valid){
                        //response success
                        //show start button
                        this.setState({
                            loading_question: false,
                            ongoing: data.ongoing,
                            currentCloseRoundDate : this.convertToLocalTime(data.round_closed_datetime),
                            message : '',
                            isValidToStart : true
                        });
                    }else{
                        //response failed
                        this.setState({
                            loading_question: false,
                            isValidToStart : false,
                            message: data.error_messages.length > 0 ? data.error_messages.map(errorMessage => ( errorMessage )) : data.message
                        });
                    }
                });
            }
            else{
                //response failed
                this.setState({
                    loading_question: false,
                    isValidToStart : false,
                    message: "Failed to get response!"
                });
            }
        })
    }

    convertToLocalTime(closeRoundTime){
        var localDate = moment.utc(closeRoundTime, "YYYY-MM-DD HH:mm:ss").local().format('DD/MM/YYYY [at] HH:mm');

        if(isDebug){
            console.log("Local Time: "+localDate);
        }

        return localDate;
    }
    
    dateToReadableFormat(date){
        return this.make2Digits(date.getDate())+'/'+this.make2Digits(date.getMonth())+'/'+date.getFullYear()+' at '+this.make2Digits(date.getHours())+':'+this.make2Digits(date.getMinutes());
    }

    make2Digits(number){
        return (number<10?'0':'') + number;
    }

    render(){
        return(
            <div>
                <div className="body-dashboard">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-welcome">
                                <span>Hi { this.props.user.first_name }</span>
                                <span>Welcome to The Bridgestone Challenge! Each round will consist of 3 multiple choice questions.</span>
                                <span>The timer starts when you click Start Round and stops once you click Finish on Question 3. You will have one attempt per round.</span>
                                <span>Answer the most questions correctly and in the shortest amount of time to win.</span>
                            </div>

                            <div className="col-lg-3 button-round">
                                { this.state.loading_question ? (
                                    <button type="button" className="btn btn-danger btn-lg btn-closed"><span>Loading Round</span></button>
                                ) : (
                                    this.state.isValidToStart ? (
                                        <div>
                                            { !this.state.ongoing ? (
                                                <Link className="btn btn-round btn-danger btn-lg" data-toggle="modal" data-target="#startRoundConfirmationModal" to="#!">Start Round</Link>
                                            ) : (
                                                <Link className="btn btn-round btn-danger btn-lg" data-toggle="modal" data-target="#oneAttemptUsedModal" to="#!">Start Round</Link>
                                            ) }
                                            <div className="round-closes">
                                                Round closes { this.state.currentCloseRoundDate }
                                            </div>
                                            
                                        </div>
                                    ) : (
                                        <button type="button" className="btn btn-danger btn-lg btn-closed"><span>Round Closed</span></button>
                                    )
                                ) }
                            </div>           
                        </div>
                        <div className="row justify-content-center wrap-line-proggress">
                            <div className="col-md-10 line-progress"></div>
                        </div>

                        <RoundInfo/>
                    </div>
                </div>
                <Prize />
                <RoundWinner />
                <div className="body-footer">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="footer">
                                <span className="copyright">
                                    © COPYRIGHT BRIDGESTONE AUSTRALIA
                                </span>
                                <span className="text-right-footer">
                                        <Link to={`${this.props.sitePath}/account`}>ACCOUNT</Link> <span className="line-vertical"></span>
                                        <a href="#!" to="#!" data-toggle="modal" data-target="#termsModal">TERMS</a> <span className="line-vertical"></span>
                                        <a href="#!" to="#!" data-toggle="modal" data-target="#privacyPolicyModal">PRIVACY POLICY</a>
                                </span>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="termsModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content rounded-0">
                            <div className="modal-body modal-privacy">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12 wrap-privacy">
                                            <TermsAndConditions />
                                        </div>
                                    </div>
                                    <div className="row wrap-btn-step">
                                        <button type="button" className="btn btn-secondary mx-auto btn-step" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PrivacyPolicy />

                <StartRoundConfirmationModal history={this.props.history}/>
                <OneAttemptUsedModal showCloseButton={false} history={this.props.history}/>

            </div>
            
        );
    }
}

const mapStateToProps = state => ({
    sitePath : state.siteReducer.site_path,
    user : state.userReducer.user
})

export default connect(mapStateToProps, {
    setRound
})(Home);