import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../redux/actions/userAction';
import { isDebug } from '../../config';

class Login extends Component{
    constructor(){
        super();

        //set default state
        this.state = {
            email_address : '',
            password: '',
            site_id: 0,
            is_loading: false,
            woi : '',
            errorMessages: [],
            is_alert: true
        }

        //bind function
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        //set site id
        this.setState({ site_id: this.props.site.id });
    }

    handleTextChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }

    handleSubmit(e){
        e.preventDefault();

        /* Validation */
        let isValid = this.validateInput();

        if(isValid){
            //set loading
            this.setState({
                is_loading: true,
                errorMessages: []
            });

            //login
            this.props.login({
                email_address: this.state.email_address,
                password: this.state.password,
                site_id: this.state.site_id
            },(response) => {
                if(isDebug){
                    console.log(response);
                }
                //set loading
                this.setState({
                    is_loading: false
                });

                //get error
                if(!response.is_valid){
                    this.setState({ 
                        errorMessages: response.error_messages,
                        password : '' //reset password
                    });
                }else{
                    //show toast
                    if(typeof response.data.first_name !== 'undefined'){
                        // toast.info("Welcome, "+response.data.first_name, {
                        //     position: toast.POSITION.BOTTOM_RIGHT
                        // });
                    }
                }
            })
        }    
    }

    validateInput(){
        let errorMessages = [];

        let emailAddress = document.getElementsByName('email_address')[0];
        let password = document.getElementsByName('password')[0];

        //alert error
        this.setState({
            is_alert: true 
        })

        //validate email
        if(this.state.email_address.length < 1 || this.state.email_address==null){
            this.showError(emailAddress, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(emailAddress, false, '');
        }

        //validate password
        if(this.state.password.length < 1 || this.state.password==null){
            this.showError(password, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(password, false, '');
        }

        //check error
        if(errorMessages.length > 0){
            return false;
        }else{
            //no error
            return true;
        }
    }
    
    showError(element, show, message){
        let parent = element.parentElement;
        if(isDebug){
            console.log(parent);
        }
        let errorText = parent.querySelector('.text-error');
        if(show){
            element.classList.add('border-error');
            errorText.innerHTML = message;
        }else{
            //hide error
            element.classList.remove('border-error');
            errorText.innerHTML = "";
        }
    }

    removeAlert = () => {
        this.setState({
            is_alert: false 
        })
    }

    renderBodyContents = () => {
        return {__html: this.state.woi}
    }

    render(){
        return(
            <div className="bg-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 login-page px-0">
                            <div dangerouslySetInnerHTML={this.renderBodyContents()}></div>
                            { this.state.errorMessages.map((error, key) => (
                                 this.state.is_alert ? 
                                <div key={key} className="alert alert-danger alert-ui alert-dismissible fade show">
                                    { error }
                                    <button type="button" className="close" onClick={() => this.removeAlert()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div> : "" 
                            )) }
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input type="email" name="email_address" value={this.state.email_address} onChange={this.handleTextChange} className="form-control form-regist mt-0" placeholder="Email"/>
                                    <small className="form-text text-error"></small>
                                </div>
                                <div className="form-group form-password">
                                    <input type="password" name="password" value={this.state.password} onChange={this.handleTextChange}  className="form-control form-regist" placeholder="Password"/>
                                    <small className="form-text text-error"></small>
                                </div>                    
                                <div className="form-group text-center wrap-btn-step"> 
                                    { this.state.is_loading ? (
                                        <button type="button" className="btn btn-dark btn-step" disabled>Signing In...</button>
                                    ) : (
                                        <button type="submit" className="btn btn-dark btn-step">Sign In</button>
                                    )}
                                </div>
                                <div className="footer-text">
                                    <div className="forgot-password">
                                        <Link to={`${this.props.site.site_path}/forgot-password`} >Forgot Password?</Link>
                                    </div>
                                    <div className="register">
                                        <span>Don’t have an account? </span><Link to={`${this.props.site.site_path}/signup`} >Register here</Link>
                                    </div>
                                </div>
                            </form>
                        </div>              
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer
});

export default connect(mapStateToProps, {
    login
})(Login);