import React, { Component } from 'react';

class Loading extends Component{
    render(){
        return(
            <div className="loading">
               <img alt="" src={process.env.PUBLIC_URL + '/imgs/loading.gif'} />
            </div>
        );
    }
}

export default Loading;