import React, { Component } from 'react';
import TellUs from '../TellUs';
import { isDebug } from '../../config';

class CheckEmail extends Component{
    constructor(props){
        super();
        if(isDebug){
            console.log(props);
        }

        this.state = {
            email_address : props.email_address
        }
    }

    render(){
        return(
            <div className="col-md-6 px-0 text-center email">
                <h1 className="mb-10">Check your email.</h1>
                <p className="email-adress">
                    We’ve sent a message to { this.state.email_address } with a link to activate your account.
                </p>

                <div className="tell-us">
                    <TellUs/>
                </div>  
            </div>
        );
    }
}

export default CheckEmail;