/* Site */
export const GET_SITE = 'GET_SITE';
export const SET_SITE = 'SET_SITE';
/* Site */

/* Auth */
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
/* Auth */

/* Round */
export const SET_ROUND = 'SET_ROUND';
export const DELETE_ROUND = 'DELETE_ROUND';
/* Round */