import React, { Component } from 'react';
import { connect } from 'react-redux';

class StartRoundConfirmationModal extends Component{
    constructor(props){
        super(props);

        //bind function
        this.start = this.start.bind(this);
    }

    start(){
        //hide modal
        document.getElementById("startRoundConfirmationModal").click();

        //start round
        this.props.history.push(`${this.props.sitePath}/round`);
    }

    render(){
        return(
            <div className="modal fade wrap-modal-star-round" id="startRoundConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-star-round" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div align="center">
                                <div className="title">You have one attempt per round.</div>
                                <div className="text-round">
                                    <span className="text-child">Once you start the round do not close the window or go back. Doing so will disqualify your entry for the round.</span>    
                                    <span>I understand and I'm ready to begin the round.</span> 
                                    
                                </div>
                                <button type="button" onClick={this.start} className="btn btn-round btn-danger btn-lg">Start Round</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sitePath : state.siteReducer.site_path,
});

export default connect(mapStateToProps, {
})(StartRoundConfirmationModal);