import React, { Component } from 'react';

class Timezone extends Component{
    constructor(){
        super();

        this.state = {
            timezone_offset : ''
        }

        this.getTimeZone = this.getTimeZone.bind(this);
    }

    componentDidMount(){
        //get timezone
        this.setState({
            timezone_offset : this.getTimeZone()
        });
    }

    getTimeZone() {
        var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
        return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
    }

    render(){
        return(
            <div align="center">
                <b>My Timezone offset:</b>
                <br/>
                { this.state.timezone_offset } From UTC
            </div>
        );
    }
}

export default Timezone;