import React, { Component } from 'react';
import { apiUrl, apiSettings, isDebug } from '../../config';
import { connect } from 'react-redux';
import { getAccessToken } from '../../redux/actions/userAction';

//component
import Loading from '../Loading';

//url
const getRoundWinnersUrl = apiUrl + 'Round/GetRoundWinners';

class RoundWinner extends Component{
    constructor(){
        super();
        this.state = {
            is_loading : false,
            is_success : false,
            message : '',
            winners : [],
            empty_winners : false
        }
    }

    componentDidMount(){
        this.getWinners();
    }

    getWinners(){
        //set loading
        this.setState({ is_loading : true, message: '', is_success : false });

        let body = {
            secret_key: apiSettings.secret_key,
            token: apiSettings.token,
            access_token: getAccessToken()
        };

        fetch(getRoundWinnersUrl, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    if(isDebug){
                        console.log(data);
                    }
                    //check if valid
                    if(data.is_valid){    
                        this.setState({ message : '', is_loading : false, is_success: true, winners : data.data });
                        if(this.state.winners.length === 0){
                            this.setState({ empty_winners : true });
                        }
                    }else{
                        this.setState({ is_loading : false, message : data.message });
                    }
                });
            }
            else{
                //response failed
                this.setState({ is_loading : false, message : "Failed to get response from server" });
            }
        }).catch((err) => {
            this.setState({ is_loading : false, message : "Failed to connect API." });
        })
    }

    render(){
        return(
            <div>
                <div className="body-winners">
                    <div className="container">
                        <div className="row justify-content-center">
                            { this.state.is_loading ? <Loading /> : (
                                <div className="col-lg-6 col-md-9 wrap-winners ">
                                    <h1>Round winners</h1>
                                    <p>Congratulations to all winners. A different prize is up for grabs each round.</p>
                                    {!this.state.empty_winners ? (
                                    <div className="area-table-scroll-x">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="hd-round-tbl" >Round</th>
                                                    <th className="hd-winner-tbl">Winner</th>
                                                    <th className="hd-winner-tbl">Location</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.state.winners.map((winner, key) => (
                                                    <tr key={key}>
                                                        <th scope="row">{ winner.name }</th>
                                                        <td>{ winner.user_name }</td>
                                                        <td>{ winner.location_name }</td>
                                                    </tr>
                                                )) }
                                            </tbody>
                                        </table>
                                    </div>
                                    ) : '' }
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {

})(RoundWinner);