import React, { Component } from 'react';
import Counter from './Counter';
import { apiUrl, apiSettings, isDebug } from '../../config';
import { connect } from 'react-redux';
import RoundResult from './RoundResult';
import { Prompt } from "react-router-dom";

//component
import Loading from '../Loading';
import OneAttemptUsedModal from './../round/OneAttemptUsedModal';

//url
const getStartedRoundUrl = apiUrl + 'Round/StartedRound';
const getPostRoundAnswersUrl = apiUrl + 'Round/PostRoundAnswers';

class Round extends Component{
    
    constructor(){
        super();
        this.state = {
            round : {},
            answers : [],
            isValidToStart : false,
            message : "",
            startDateTime: null, 
            elapsedTime: 0, 
            currentQuestionNumber: 1, 
            isStarted: false, 
            isLoading: false, 
            startTimer: false, 
            roundName: "1",
            questionPosition: 0,
            isLastQuestion : false,
            errorMessages : [],
            isFinish : false,
            is_ongoing : false,
            one_attemp_body : ''
        }

        this.initRound = this.initRound.bind(this);
        this.startRound = this.startRound.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
        this.finish = this.finish.bind(this);
        this.setAnswers = this.setAnswers.bind(this);
        this.submitAnswer = this.submitAnswer.bind(this);
    }

    componentDidMount(){
        this.initRound();
    }

    preloadImage(questions){
        //loop questions
        questions.map(question => {
            if(question.question_type===2){
                //image
                const img = new Image();
                const img2 = new Image();
                const img3 = new Image();
                img.src = question.answerA; // by setting an src, you trigger browser download
                img2.src = question.answerB; // by setting an src, you trigger browser download
                img3.src = question.answerC; // by setting an src, you trigger browser download
        
                img.onload = () => {
                    // when it finishes loading, update the component state
                }
                img2.onload = () => {
                    // when it finishes loading, update the component state
                }
                img3.onload = () => {
                    // when it finishes loading, update the component state
                }
            }

            return true;
        })
    }

    initRound(){
        this.setState({ isLoading : true });

        //get data from API
        fetch(getStartedRoundUrl, {
            method : 'POST',
            body : JSON.stringify({
                access_token : this.props.user.user.access_token,
                secret_key : apiSettings.secret_key,
                token : apiSettings.token
            })
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    if(isDebug){
                        console.log(data);
                    }
                    //check if valid
                    if(data.is_valid){
                        //response success
                        let round = data.data;
                        if(typeof round !== 'undefined'){
                            //check if already on going
                            if(round.questions.length > 0){
                                if(!data.ongoing){
                                    this.setState({
                                        isValidToStart : true,
                                        round : round,
                                        questionPosition : round.questions[0].id //set first question
                                    }, () => {
                                        //load image
                                        this.preloadImage(round.questions);
                    
                                        this.startRound();
                                    });
                                }else{
                                    document.getElementById("showOneAttemptUsedModalBtn").click();
                                }
                            }else{
                                //no question found
                                this.setState({
                                    isLoading: false,
                                    isValidToStart : false,
                                    is_ongoing: false,
                                    message: "no question found!"
                                });
                            }
                        }else{
                            //no round found
                            this.setState({
                                isLoading: false,
                                isValidToStart : false,
                                is_ongoing: false,
                                message: "no round found!"
                            });
                        }
                    }else{
                        //response failed
                        this.setState({
                            isLoading: false,
                            isValidToStart : false,
                            is_ongoing: false,
                            message: data.error_messages.length > 0 ? data.error_messages.map(errorMessage => ( errorMessage )) : data.message
                        });
                    }
                });
            }
            else{
                //response failed
                this.setState({
                    isLoading: false,
                    isValidToStart : false,
                    is_ongoing: false,
                    message: "Failed to get response!"
                });
            }
        });
    }

    renderBodyContents = () => {
        return {__html: this.state.one_attemp_body}
    }

    startRound(){
        //start round
        this.setState({ startDateTime: new Date(), isStarted : true, isLoading : false, startTimer: true }, () => {
            // Enable navigation prompt
            window.onbeforeunload = function() {
                return true;
            };
        });
    }

    nextQuestion(question){
        if (document.getElementsByClassName("selected-answer")[0] === undefined){
            return false
        }
        //get next questions
        let currentId = question.id;
        let sortOrder = question.sort_order;
        let nextQuestion = this.state.round.questions.filter(question => ( question.id!==currentId && question.sort_order >= sortOrder )).map(question => {
            return question;
        });



        //check next question availability
        if(nextQuestion!==null){
            if(nextQuestion.length > 1){
                //check answer
                let answer = this.getAnswer(question.id);

                if(answer!==''){
                    //there is another question
                    this.setState({ 
                        questionPosition : nextQuestion[0].id,
                        isLastQuestion : false, //show next button
                        currentQuestionNumber : this.state.currentQuestionNumber+1, //show next button
                    });

                    //set answer
                    this.setAnswers(question.id, answer);
                }else{
                    //answer is empty
                    // toast.warn("Please choose your answer.", {
                    //     position: toast.POSITION.BOTTOM_RIGHT
                    // });
                }
            }else if(nextQuestion.length === 1){
                //check answer
                let answer = this.getAnswer(question.id)
                if(answer!==''){
                    //last question
                    this.setState({ 
                        questionPosition : nextQuestion[0].id,
                        isLastQuestion : true, //show finish button
                        currentQuestionNumber : this.state.currentQuestionNumber+1
                    });
                    //set answer
                    this.setAnswers(question.id, answer);
                }else{
                    //answer is empty
                    // toast.warn("Please choose your answer.", {
                    //     position: toast.POSITION.BOTTOM_RIGHT
                    // });
                }
            }
        }
        window.scrollBy(0,-50);
    }

    handleClickActiveAnswer = (divName) => {
        var questionClass = document.querySelectorAll(".box");
        questionClass.forEach( e =>
            e.classList.add( "no-selected" )
        );

        questionClass.forEach( e =>
            e.classList.remove( "selected-answer" )
        );

        document.getElementsByClassName(divName)[0].classList.add('selected-answer');
        document.getElementsByClassName('btn-question')[0].classList.remove('btn-preselected');
    }

    getAnswer(questionId){
            var answerValue = document.getElementsByClassName("selected-answer");
            return answerValue[0].getAttribute("value");
    }

    setAnswers(questionId, answer){
        //get answers from state
        let answers = this.state.answers;

        //push answer to var
        answers.push({
            id : questionId,
            answer: answer
        });

        //init answers to state
        this.setState({ answers : answers });
    }

    finish(question){

        if (document.getElementsByClassName("selected-answer")[0] === undefined){
            return false
        }
        //check answer
        let answer = this.getAnswer(question.id)
        if(answer!==''){
            //set last answer
            this.setAnswers(question.id, this.getAnswer(question.id));

            this.setState({ 
                startTimer: false,
                isStarted: false,
                isLoading : true
            }, () => {
                //submit data
                this.submitAnswer((res) => {
                    if(res.isSuccess){
                        this.setState({ isLoading: false, isFinish: true });

                        // Remove navigation prompt
                        window.onbeforeunload = null;
                    }else{
                        //failed to submit round
                        this.setState({ 
                            isLoading: false,
                            isValidToStart : false,
                            message : res.message
                        });
                    }
                });
            });
        }else{
            //answer is empty
            // toast.warn("Please choose your answer.", {
            //     position: toast.POSITION.BOTTOM_RIGHT
            // });
        }
    }

    submitAnswer(callback){
        let elapsedTime = Date.now() - this.state.startDateTime;

        this.setState({ elapsedTime : elapsedTime });

        let body = {
            access_token : this.props.user.user.access_token,
            round_id : this.state.round.id,
            elapsed_time : elapsedTime,
            questions : this.state.answers,
            secret_key : apiSettings.secret_key,
            token : apiSettings.token
        }

        if(isDebug){
            console.log(body);
        }

        //get data from API
        fetch(getPostRoundAnswersUrl, {
            method : 'POST',
            body : JSON.stringify(body)
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(data.is_valid){
                        //response success
                        callback({
                            isSuccess: true,
                            message : ''
                        });
                    }else{
                        //response failed
                        callback({
                            isSuccess: false,
                            message : (data.error_messages != null ? (data.error_messages.length > 0 ? data.error_messages[0] : data.message) : data.message)
                        });
                    }
                });
            }
            else{
                callback({
                    isSuccess: false,
                    message : 'failed to call server API'
                });
            }
        })
    }

    render(){
        const RenderAnswer = (props) => {
            let answer = props.answer;
            let type = props.type;

            return (
                <span>
                    { type===2 ? (
                        //image
                        <div>
                            <img alt="" src={answer}/>
                        </div>
                    ) : (
                        //text
                        <span className="text-answer">{answer}</span>
                    ) }
                </span>
            );
        }

        const Hint = (props) => {
            let hint = props.hint;
            return (
                <div>
                    { hint!=="" ? (
                        <div>
                            <img alt="" className="help-diagram" src={hint} />
                        </div>
                        ) : "" }
                </div>
            );
        }

        const RenderQuestion = (props) => {
            let question = props.question;
            let name = question.id+"_answer";

            return(
                <div className="container body-question">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 wrap-title">
                            <h3 className="title wrap-qst"><div className="qst">{question.questions}</div></h3>
                        </div>
                        <div className="col-10 d-flex wrap-answer justify-content-center">
                            <div className="answer-table justify-content-between ">
                                <div className="wrap-box">
                                    <div name={name} value="A" onClick={() => this.handleClickActiveAnswer('question1')} className="box question1">
                                        <RenderAnswer answer={question.answerA} type={question.question_type}/>
                                    </div>
                                    <div name={name} value="B" onClick={() => this.handleClickActiveAnswer('question2')} className="box question2">
                                        <RenderAnswer answer={question.answerB} type={question.question_type}/>
                                    </div>
                                    <div name={name} value="C" onClick={() => this.handleClickActiveAnswer('question3')} className="box question3">
                                        <RenderAnswer answer={question.answerC} type={question.question_type}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="row justify-content-center wrap-btn-qst">
                                { props.isFinish ? (
                                    <button type="button" onClick={ this.finish.bind(this, question) } className="btn btn-dark btn-question btn-preselected">Finish</button>
                                ) : (
                                    <button type="button" onClick={ this.nextQuestion.bind(this, question) } className="btn btn-dark btn-question btn-preselected">Next</button>
                                ) }
                            </div>
                            <div className="row justify-content-center">
                                <Hint hint={question.hint} />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const ErrorMessageRound = () => {
            return(
                <div className="bg-content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 px-0 text-center thanks">
                                <h1 className="mb-6">{this.state.message}</h1></div>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            <div>            
                <OneAttemptUsedModal history={this.props.history} showCloseButton={true}/>
                <Prompt
                    when={this.state.isStarted}
                    message={location =>
                        `Are you sure you want to leave this page?`
                    }
                />
                { this.state.isLoading ? <Loading /> : (
                    <div>
                        { this.state.isValidToStart ? (
                            this.state.isFinish ? 
                            //show round result
                            <RoundResult round={this.state.round} elapsedTime={this.state.elapsedTime} answers={this.state.answers}/> : 
                            //show round
                            (
                                <div>
                                    <div id="timer" className="bg-timer fixed-time">
                                        <div className="container timer">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-9 wrap-question-time d-flex justify-content-between">
                                                    <div className="question">R{ this.state.round.name }:Q{this.state.currentQuestionNumber}</div>
                                                    <div className="time"><img alt="time icon" src={process.env.PUBLIC_URL + '/imgs/iconTime.svg'}/>
                                                        <Counter start={this.state.startTimer} startDateTime={this.state.startDateTime}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    { 
                                        this.state.isStarted ? 
                                        //show questions
                                        (
                                            <div>
                                                { this.state.round.questions.length > 0 ? (
                                                    this.state.round.questions.filter(question => ( question.id===this.state.questionPosition )).map((question, key) => (
                                                        <RenderQuestion key={key} question={question} isFinish={ this.state.isLastQuestion }/>
                                                    ))
                                                ) : "" }
                                            </div>
                                        ) :  <ErrorMessageRound />
                                    }
                                </div>
                            )
                        ) :  <ErrorMessageRound />
                        }
                    </div>
                ) }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user : state.userReducer,
    site : state.siteReducer,
    round : state.roundReducer
});

export default connect(mapStateToProps, {

})(Round);