import { isFullSiteFunctional } from '../config';

const countries = [
    {
        name : 'au',
        id : 1
    },
    {
        name : 'nz',
        id : 2
    },
    {
        name : 'bmsa',
        id : 3
    },
    {
        name : 'lube-mobile',
        id : 4
    }
];
const country = countries.filter(country => country.id===1)[0];
// au = 1 , nz = 2, bmsa = 3
//Site Model
export let siteModel = {
    id : country.id,
    domain: 'bsolympic.stage02.jaydapps.com/'+country.name,
    country_code: country.name.toUpperCase(),
    site_path: '/'+country.name,
    show_region: false,
    full_site: isFullSiteFunctional
}