import { SET_ROUND, DELETE_ROUND } from './actionTypes';

/*
SET ROUND
*/
export const setRound = (round) => {
    return {
        type: SET_ROUND,
        payload: round
    }
}

/*
SET ROUND
*/
export const deleteRound = () => {
    return {
        type: DELETE_ROUND
    }
}