import React, { Component } from 'react';

class Footer extends Component{
    render(){
        return(
            // <div className="footer container">
            //     Bridgestones Olympic &copy; 2019
            // </div>
            <div></div>
        );
    }
}

export default Footer;