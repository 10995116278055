import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiUrl, apiSettings, isDebug, redirectToMobileApp } from '../../config';
import { Link } from 'react-router-dom';
import Loading from '../Loading';

//url
const activateUserUrl = apiUrl + 'user/activate';

class Activate extends Component{
    constructor(){
        super();

        this.state = {
            is_loading: true,
            message: ''
        };

        this.activate = this.activate.bind(this);
    }

    activate(){
        let body = {
            secret_key : apiSettings.secret_key,
            token : apiSettings.token,
            email_address : this.props.match.params.email,
            verification_token : this.props.match.params.token,
            site_id : this.props.site.id
        }

        if(isDebug){
            console.log(body);
        }

        fetch(activateUserUrl, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(data.is_valid){    
                        this.setState({ message : '', is_loading : false });
                    }else{
                        this.setState({ is_loading : false, message : data.message });
                    }
                });
            }
            else{
                //response failed
                this.setState({ is_loading : false, message : "Failed to get response from server" });
            }
        }).catch((err) => {
            this.setState({ is_loading : false, message : "Failed to connect API." });
        })
    }

    componentWillMount(){
        //check if should open in mobile app
        if(redirectToMobileApp){
            //show confirmation
            var redirectConfirmation = window.confirm("Open in mobile app?");
            if (redirectConfirmation === true) {
                window.location.href = "bsolympic://bridgestoneolympic"+this.props.site.site_path+"/activate/"+this.props.match.params.email+"/"+this.props.match.params.token;
            } else {
                this.activate();
            }
        }else{
            this.activate();
        }  
    }

    render(){
        const Success = () => {
            return(
                <div>
                    <h1 className="title-login">You have successfully activated your account.</h1>
                    <div className="form-group text-center wrap-btn-step">
                        <Link to={`${this.props.site.site_path}/login`} className="btn btn-dark btn-step">Sign in</Link>
                    </div>
                </div>
            );
        }

        const Error = () => {
            return(
                <div>
                    { this.state.message }
                </div>
            );
        }

        return(
            <div className="bg-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 px-0 text-center email">
                            { this.state.is_loading ? <Loading /> : (
                                this.state.message==='' ? <Success /> : <Error />
                            ) }
                        </div>              
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer
});

export default connect(mapStateToProps, {
})(Activate);