import React, { Component } from 'react';
import { connect } from 'react-redux';
import contentItemNames from '../../constants/contentItemNames';
import apiHelper from '../../helpers/apiHelper';

//component
import Loading from './../Loading';

class Prize extends Component{
    constructor(){
        super();

        this.state = {
            error_message : '',
            is_loading : false,
            body : ''
        }

        this.getPrize = this.getPrize.bind(this);
    }

    componentWillMount(){
        this.getPrize(this.props.site.id);
    }

    getPrize(siteId){
        //set loading
        this.setState({
            is_loading : true
        });

        apiHelper.getContenItem(contentItemNames.PRIZE_CONTENT, siteId, (res) => {
            //check if valid
            if(res.is_valid){
                //response success
                this.setState({
                    body : res.data.body,
                    error_message : '',
                    is_loading : false
                });
            }else{
                //response failed
                this.setState({
                    error_message: res.message,
                    is_loading : false
                });
            }
        });
    }

    renderBodyContents = () => {
        return {__html: this.state.body}
    }

    render(){
        return(
            <div>
                { this.state.is_loading ? <Loading /> : (
                    <div dangerouslySetInnerHTML={this.renderBodyContents()}></div>
                ) }
                {/* <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-9 wrap-champion">
                            <h3>The overall challenge champion can choose from one of the following major prizes.</h3>
                            <p>Includes tickets, travel & accomodation for 2 people and $1,000 spending money.</p>
                        </div>
                        <div className="col-10 d-flex dashboard-round justify-content-center">
                            <div className="champion-table justify-content-between ">
                                <div className="wrap-box">
                                    <div className="box first-box">
                                        <div className="icon mx-auto">
                                            <img alt="icon AFL" src={process.env.PUBLIC_URL + '/imgs/AFL-Icon.svg'} />
                                        </div>
                                        <span className="text-caption">2020 AFL Grand Final package</span>
                                    </div>
                                    <div className="box">
                                        <div className="icon mx-auto">
                                            <img alt="icon Rugby" src={process.env.PUBLIC_URL + '/imgs/Rugby-Icon.svg'} />
                                        </div>
                                        <span className="text-caption">2020 NRL Premiership Grand Final package</span>
                                    </div>
                                    <div className="box">
                                        <div className="icon mx-auto">
                                            <img alt="icon Tennis" src={process.env.PUBLIC_URL + '/imgs/Tennis-Icon.svg'} />
                                        </div>
                                        <span className="text-caption">2021 Australian Open VIP package</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer
})

export default connect(mapStateToProps, {

})(Prize);