import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/userAction';

class LogoutModal extends Component{
    constructor(){
        super();

        this.state = {
            is_loading : false
        }

        //bind function
        this.logout = this.logout.bind(this);
    }

    logout(){
        this.setState({ is_loading : true });
        this.props.logout((isSuccess) => {
            //set loading false
            this.setState({ is_loading : false }, () => {
                //check if succes or not
                if(isSuccess){
                    //show toast
                    // toast.info("Logout Successfull.", {
                    //     position: toast.POSITION.BOTTOM_RIGHT
                    // });
                    document.getElementById("closeLogoutModal").click();
                }else{
                    //show toast
                    // toast.error("Logout Failed!", {
                    //     position: toast.POSITION.BOTTOM_RIGHT
                    // });
                }
            });
        });
    }

    render(){
        return(
            <div className="modal fade" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            { this.state.is_loading ? (
                                <div align="center">Logging out...</div>
                            ) : (
                                <div>
                                    Logout?
                                    <br/><br/>
                                    <div align="center">
                                        <a href="#!" className="btn btn-primary" onClick={ this.logout }>Yes</a>&nbsp;&nbsp;&nbsp;
                                        <a href="#!" id="closeLogoutModal" className="btn btn-danger" data-dismiss="modal">Close</a>
                                    </div>
                                </div>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps, {
    logout
})(LogoutModal);