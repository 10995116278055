import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiUrl, apiSettings, isDebug } from '../../config';
import { Link } from 'react-router-dom';
import { getAccessToken, getUserDetails } from '../../redux/actions/userAction';
import Loading from '../Loading';

//url
const updateDetailsUrl = apiUrl + 'User/UpdateDetails';

class Account extends Component{
    constructor(){
        super();

        this.state = {
            is_submit_loading: false,
            is_loading: false,
            is_success: false,
            is_valid_to_change: false,
            message: '',
            first_name : '',
            last_name : '',
            phone_area : '',
            phone_number : '',
            email_address : '',
            notification_email: false,
            notification_sms: false,
        }

        //bind function
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setNotificationMethod = this.setNotificationMethod.bind(this);
        this.updateDetails = this.updateDetails.bind(this);
    }

    componentDidMount(){
        this.getDetails();
    }

    getDetails(){
        this.setState({ is_loading : true, message : '' });

        getUserDetails((res) => {
            if(isDebug){
                console.log(res);
            }
            if(res.is_valid){
                //success to get details
                let user = res.data;

                //set data to input
                this.setState({
                    is_valid_to_change : true,
                    is_loading : false, 
                    message : '',
                    first_name : user.first_name,
                    last_name : user.last_name,
                    email_address : user.email_address,
                    phone_area : user.phone_area,
                    phone_number : user.phone_number,
                    notification_email : user.notification_email,
                    notification_sms : user.notification_sms
                }, () => {
                    this.initPreferredNotificationMethod();
                });
            }else{
                //failed to get details
                this.setState({ is_loading : false, message : res.message });
            }
        });
    }

    initPreferredNotificationMethod(){
        let email = document.getElementById("inlineCheckbox1");
        let sms = document.getElementById("inlineCheckbox2");
        let both = document.getElementById("inlineCheckbox3");

        if(this.state.notification_email && this.state.notification_sms){
            email.checked = false;
            sms.checked = false;
            both.checked = true;
        }else if(!this.state.notification_email && this.state.notification_sms){
            email.checked = false;
            sms.checked = true;
            both.checked = false;
        }else if(this.state.notification_email && !this.state.notification_sms){
            email.checked = true;
            sms.checked = false;
            both.checked = false;
        }else{
            //no notification method selected
            email.checked = false;
            sms.checked = false;
            both.checked = false;
        }
    }

    handleTextChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }

    handleSubmit(e){
        e.preventDefault();

        //trim object
        let user = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            phone_area: this.state.phone_area,
            phone_number: this.state.phone_number,
            email_address: this.state.email_address,  
            notification_email: this.state.notification_email,
            notification_sms: this.state.notification_sms
        };

        let isValid = this.validateInput(user);
        if(isValid){
            //update
            this.updateDetails(user);
        }else{
            //invalid input
        }
    }

    setNotificationMethod(e){
        if(e.target.value==='email'){
            this.setState({
                notification_email: true,
                notification_sms : false
            });
        }else if(e.target.value==='sms'){
            this.setState({
                notification_email: false,
                notification_sms : true
            });
        }else if(e.target.value==='both'){
            this.setState({
                notification_email: true,
                notification_sms : true
            });
        }else{
            this.setState({
                notification_email: false,
                notification_sms : false
            });
        }
    }

    validateInput(user){
        let errorMessages = [];

        let firstName = document.getElementsByName('first_name')[0];
        let lastName = document.getElementsByName('last_name')[0];
        let phoneNumber = document.getElementsByName('phone_number')[0];
        let phoneArea = document.getElementsByName('phone_area')[0];
        let emailAddress = document.getElementsByName('email_address')[0];

        //validate firstname
        if(user.first_name.length < 1 || user.first_name==null){
            this.showError(firstName, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(firstName, false, '');
        }

        //validate lastName
        if(user.last_name.length < 1 || user.last_name==null){
            this.showError(lastName, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(lastName, false, '');
        }

        //validate phoneNumber
        if((user.phone_number.length < 1 || user.phone_number==null) || (user.phone_area.length < 1 || user.phone_area==null)){
            this.showError(phoneNumber, true, 'Field is required');
            this.showError(phoneArea, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(phoneNumber, false, '');
            this.showError(phoneArea, false, '');
        }

        //validate email
        if(user.email_address.length < 1 || user.email_address==null){
            this.showError(emailAddress, true, 'Field is required');
            errorMessages.push(true);
        }else{
            this.showError(emailAddress, false, '');
        }

        //check error
        if(errorMessages.length > 0){
            return false;
        }else{
            //no error
            return true;
        }
    }

    updateDetails(user){
        //set loading
        this.setState({ is_submit_loading : true, message: '', is_success : false });

        let body = Object.assign(
            {},
            user,
            {
                secret_key: apiSettings.secret_key,
                token: apiSettings.token,
                access_token: getAccessToken()
            }
        );

        fetch(updateDetailsUrl, {
            method: 'POST',
            body: JSON.stringify(body)
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(data.is_valid){    
                        this.setState({ message : '', is_submit_loading : false, is_success: true });
                    }else{
                        this.setState({ is_submit_loading : false, message : data.message });
                    }
                });
            }
            else{
                //response failed
                this.setState({ is_submit_loading : false, message : "Failed to get response from server" });
            }
        }).catch((err) => {
            this.setState({ is_submit_loading : false, message : "Failed to connect API." });
        })
    }

    showError(element, show, message){
        let parent = element.parentElement;
        let errorText = parent.querySelector('.text-error');
        if(show){
            element.classList.add('border-error');
            errorText.innerHTML = message;
        }else{
            //hide error
            element.classList.remove('border-error');
            errorText.innerHTML = "";
        }
    }

    removeAlert = () => {
        this.setState({
            is_success: false 
        })
    }

    render(){
        return(
            <div className="bg-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-4 px-0">
                            { this.state.is_success ? 
                            <div className="alert alert-success alert-ui alert-dismissible fade show">
                            <strong>Success</strong>
                            <button type="button" className="close" onClick={() => this.removeAlert()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div> : "" }
                            <h1>Account details</h1>
                            { this.state.is_loading ? <Loading /> : (
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input name="first_name" value={this.state.first_name} onChange={this.handleTextChange} maxLength="50" className="form-control form-regist" type="text" placeholder="First Name" readOnly={ this.state.is_valid_to_change ? '' : 'readonly' }/>
                                        <small className="form-text text-error"></small>
                                    </div>
                                    <div className="form-group">
                                        <input name="last_name" value={this.state.last_name} onChange={this.handleTextChange} maxLength="50" className="form-control form-regist" type="text" placeholder="Last Name" readOnly={ this.state.is_valid_to_change ? '' : 'readonly' }/>
                                        <small className="form-text text-error"></small>
                                    </div>
                                    <div className="input-group form-group form-phone">
                                        <input name="phone_area" value={this.state.phone_area} onChange={this.handleTextChange} maxLength="3" className="form-control code-phone" type="text" placeholder="+61" readOnly={ this.state.is_valid_to_change ? '' : 'readonly' }/>
                                        <input name="phone_number" value={this.state.phone_number} onChange={this.handleTextChange} maxLength="20" className="form-control no-phone" type="text" placeholder="Mobile" readOnly={ this.state.is_valid_to_change ? '' : 'readonly' }/>
                                        <small className="form-text text-error"></small>
                                    </div>
                                    <div className="form-group">
                                        <input name="email_address" value={this.state.email_address} onChange={this.handleTextChange} maxLength="150" className="form-control form-regist" type="text" placeholder="Email" readOnly={ this.state.is_valid_to_change ? '' : 'readonly' }/>
                                        <small className="form-text text-error"></small>
                                    </div>
                                    <div className="form-group notification" onChange={this.setNotificationMethod}>
                                        <label>Preferred notification method </label>
                                        <div className="group-notif d-flex  justify-content-between">
                                            <div className="form-check form-check-inline radio-btn">
                                                <input className="form-check-input" type="radio" name="radiobutton" id="inlineCheckbox1" value="email" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Email</label>
                                            </div>
                                            <div className="form-check form-check-inline radio-btn">
                                                <input className="form-check-input" type="radio" name="radiobutton" id="inlineCheckbox2" value="sms" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox2">SMS</label>
                                            </div>
                                            <div className="form-check form-check-inline radio-btn">
                                                <input className="form-check-input" type="radio" name="radiobutton" id="inlineCheckbox3" value="both" />
                                                <label className="form-check-label" htmlFor="inlineCheckbox3">Both</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group wrap-btn-step text-center">
                                        { this.state.is_submit_loading ? (
                                            <button type="button" className="btn btn-dark btn-step" disabled>Saving...</button>
                                        ) : (
                                            <button type="submit" className="btn btn-dark btn-step" disabled={ this.state.is_valid_to_change ? '' : 'disabled' }>Save</button>
                                        ) }   
                                        <br/>
                                        { this.state.message }
                                    </div>
                                    <div className="footer-text">
                                        <div className="register account-page">
                                        <Link to={`${this.props.sitePath}/`}>Cancel</Link>
                                        </div>
                                    </div>
                                </form>
                            ) }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    sitePath : state.siteReducer.site_path,
    user : state.userReducer.user
})

export default connect(mapStateToProps, {
    
})(Account);