import { SET_SITE } from './actionTypes';
import { apiUrl, apiSettings, isFullSiteFunctional, isDebug, tempSiteUrl, liveSiteUrl } from '../../config';
import { siteModel } from '../../models/siteModel';

const getSiteUrl = apiUrl+'site/get';

/*
INIT SITE:
- get host first ()
- get site from domain name
*/
export const initSite = (callback) => dispatch => {
    let host = window.location.hostname; //init host 

    //au or nz
    let site = getSiteFromUrl();

    //check if from localhost or production
    if(host.toLowerCase().includes("localhost")){
        if(isDebug){
            console.log(siteModel);
        }
        callback({
            is_valid: true,
            country: siteModel.site_path
        });
    }else{
        //check if from temp site (bschallenge.jaydapps.com)
        // then replace domain to live site (https://thebridgestonechallenge.com/)
        if(site.host.toLowerCase().includes(tempSiteUrl)){
            site.host = liveSiteUrl;
        }

        console.log(site);

        //get site by domain name
        fetch(getSiteUrl, {
            method: 'POST',
            body: JSON.stringify({ 
                domain: site.host+'/'+site.country_path,
                secret_key : apiSettings.secret_key,
                token : apiSettings.token
            })
        })
        .then(function (response) {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    if(isDebug){
                        console.log(data);
                    }
                    //check if valid
                    if(data.is_valid){
                        //add site path to model
                        let body = Object.assign({}, data.data, 
                            { 
                                site_path: '/'+site.country_path,
                                full_site: isFullSiteFunctional
                            });
                        if(isDebug){
                            console.log(body);
                        }

                        //set site           
                        dispatch({
                            type: SET_SITE,
                            payload: body
                        });

                        callback({
                            is_valid: true,
                            country: '/'+site.country_path
                        });
                    }else{
                        callback({
                            is_valid: false,
                            country: '/'+site.country_path
                        });
                    }
                });
            }
            else{
                //response failed
                callback({
                    is_valid: false,
                    country: '/'+site.country_path
                });
            }
        })
        .catch(function (error) {
            console.log(error);
            callback({
                is_valid: false,
                country: '/'+site.country_path
            });
        });
    }
}

/*
Get Site Country From URL
*/
export const getSiteFromUrl = () => {
    let host = window.location.hostname; //init host 

    //au or nz
    let firstPath = window.location.pathname.split('/')[1];

    return {
        host: host,
        country_path : firstPath
    };
}