import React, { Component } from 'react';
import { connect } from 'react-redux';

class Header extends Component{
    render(){
        return(
            <div className="logo">
                <div className="container">
                    <div className="row logo-bridgestrone">
                        <img alt="the bridgestone challenge" src={process.env.PUBLIC_URL + '/imgs/logo-bridgestone-challenge.svg'} className="challenge" />
                        <img alt="Worldwide Olympic Partner, Bridgestone, Worldwide Paralympic Partner" src={process.env.PUBLIC_URL + '/imgs/BridgestoneTriComposite.svg'} className="three-logo" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    is_authenticated : state.userReducer.is_authenticated,
    sitePath : state.siteReducer.site_path
});

export default connect(mapStateToProps, {
})(Header);