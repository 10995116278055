import React, { Component } from 'react';

class ComingSoon extends Component{
    render(){
        return(
            <div className="bg-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 px-0 text-center thanks">
                            <h1 className="mb-6">Thanks for registering to participate in The Bridgestone Challenge.</h1>
                            <p>You will be notified via your preferred communication method when Round 1 is open.</p>
                            <h1>Good luck!</h1>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ComingSoon;