import { SET_ROUND, DELETE_ROUND } from '../actions/actionTypes';

//init default site model
const initialState = {};

export default (state = initialState, action) => {
    switch(action.type){
        case SET_ROUND:
            return Object.assign(
                {},
                state,
                action.payload
            );
        case DELETE_ROUND:
            return {};
        default:
            return (state);
    }
}