import React, { Component } from 'react';

class Round extends Component{
    constructor(props){
        super(props);
        this.state = {
            startDateTime: null, 
            endDateTime: null, 
            elapsed: 0,
            isOn: false
        }

        this.startTimer = this.startTimer.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.getTimeSpan = this.getTimeSpan.bind(this);
    }

    //resume timer from setted date
    componentWillReceiveProps(newProps){
        if(newProps.start){
            this.startTimer(newProps.startDateTime);
        }else{
            this.stopTimer();
        }
    }

    //first load
    componentDidMount(){
        if(this.props.start){
            this.startTimer(this.props.startDateTime);
        }else{
            this.stopTimer();
        }
    }

    componentWillMount(){
        clearInterval(this.timer);
    }

    startTimer(startDateTime){
        //start round
        this.setState(
            { 
                isOn : true,
                startDateTime : startDateTime
            }
        );

        //clear interval to prevent multiple timer obj
        clearInterval(this.timer);

        //set timer interval
        this.timer = setInterval(() => {
            //calculated elapsed time
            var elapsed = Date.now() - this.state.startDateTime;
            this.setState({elapsed: elapsed});
        }, 33);
    }

    stopTimer(){
        clearInterval(this.timer);
    }

    getTimeSpan(elapsed){ // 754567(ms) -> "12:34.567"
        var m = String(Math.floor(elapsed/1000/60)+100).substring(1);
        var s = String(Math.floor((elapsed%(1000*60))/1000)+100).substring(1);
        var ms = String(elapsed % 1000 + 1000).substring(1);
        return m+":"+s+"."+ms;
    }


    render(){
        return(
            <div className="text-time">
                { this.getTimeSpan(this.state.elapsed) }
            </div>
        );
    }
}

export default Round;