import React, { Component } from 'react';
import { connect } from 'react-redux';
import { apiUrl, apiSettings, isDebug } from '../../config';

//url
const forgotPasswordUrl = apiUrl + 'User/ForgotPassword';

class ForgotPassword extends Component{
    constructor(){
        super();

        this.state = {
            email_address: '',
            message: '',
            is_loading: false,
            is_success: false,
            is_alert_succes:true,
            is_alert_failed:true
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleTextChange(e){
        this.setState({ [e.target.name] : e.target.value });
    }

    handleSubmit(e){
        e.preventDefault();

        //set loading
        this.setState({
            is_loading: true,
        }); 

        //submit
        let body = {
            email_address : encodeURIComponent(this.state.email_address),
            site_id: this.props.site.id,
            secret_key : apiSettings.secret_key,
            token : apiSettings.token
        };

        if(isDebug){
            console.log(body);
        }

        //get data from API
        fetch(forgotPasswordUrl, {
            method : 'POST',
            body : JSON.stringify(body)
        }).then((response) => {
            //check response status
            if(response.status===200){
                //response oke, get response data
                response.json().then(data => {
                    //check if valid
                    if(isDebug){
                        console.log(data);
                    }
                    if(data.is_valid){
                        //response success
                        this.setState({
                            message : '',
                            is_alert_succes:true
                        });
                    }else{
                        //response failed
                        this.setState({
                            message: data.message,
                            is_alert_failed:true,
                        });
                    }

                    //disable loading
                    this.setState({
                        is_loading: false,
                        is_success: data.is_valid
                    }); 
                });
            }
            else{
                //response failed
                this.setState({
                    message: "Failed to get response from server!",
                    is_loading : false,
                    is_success : false,
                    is_alert_failed:true
                });
            }
        });
    }

    removeAlert = () => {
        this.setState({
            is_alert_succes:false,
            is_alert_failed:false
        })
    }
    

    render(){
        const Messages = () => {
            return(
                <div>
                    {
                        this.state.is_success ? (
                            this.state.is_alert_succes ? 
                            <div  className="alert alert-success alert-ui alert-dismissible fade show">
                                Success
                                <button type="button" className="close" onClick={() => this.removeAlert()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> : "" 
                        ) : (
                            this.state.message!=='' ? 
                            (
                                this.state.is_alert_failed ? 
                                <div  className="alert alert-danger alert-ui alert-dismissible fade show">
                                    { this.state.message }
                                    <button type="button" className="close" onClick={() => this.removeAlert()}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div> : "" 
                            ) : ''
                        )
                    }
                </div>
            )
        }

        return(     
            <div className="bg-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 px-0">
                            <Messages />
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input type="email" name="email_address" value={this.state.email_address} onChange={this.handleTextChange} className="form-control form-regist mt-sm-0 mb-30" placeholder="Email" required/>
                                </div>        
                                <div className="form-group text-center wrap-btn-step"> 
                                    { this.state.is_loading ? (
                                        <button type="button" className="btn btn-dark btn-step btn-forgot" disabled>Sending Link..</button>
                                    ) : (
                                        <button type="submit" className="btn btn-dark btn-step btn-forgot">Submit</button>
                                    )}
                                </div>
                            </form>
                        </div>              
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer
});

export default connect(mapStateToProps, {
})(ForgotPassword);