import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Link } from "react-router-dom";
import contentItemNames from '../../constants/contentItemNames';
import apiHelper from '../../helpers/apiHelper';

//component
import Loading from './../Loading';

class OneAttemptUsedModal extends Component{
    constructor(){
        super();

        this.state = {
            error_message : '',
            is_loading : false,
            body : ''
        }

        this.getOneAttempUsed = this.getOneAttempUsed.bind(this);
    }

    componentWillMount(){
        this.getOneAttempUsed(this.props.site.id);
    }

    getOneAttempUsed(siteId){
        //set loading
        this.setState({
            is_loading : true
        });

        apiHelper.getContenItem(contentItemNames.ONE_ATTEMPT_USED, siteId, (res) => {
            //check if valid
            if(res.is_valid){
                //response success
                this.setState({
                    body : res.data.body,
                    error_message : '',
                    is_loading : false
                });
            }else{
                //response failed
                this.setState({
                    error_message: res.message,
                    is_loading : false
                });
            }
        });
    }

    renderBodyContents = () => {
        return {__html: this.state.body}
    }

    render(){
        return(
            <div>
                <Link id="showOneAttemptUsedModalBtn" className="btn btn-round btn-danger btn-lg d-none" data-toggle="modal" data-target="#oneAttemptUsedModal" to="#!">Show Modal</Link>
                <div className="modal fade wrap-modal-star-round" id="oneAttemptUsedModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-star-round" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                            <a href="#!" id="closeOneAttemptUsedModal" className="btn btn-danger d-none" data-dismiss="modal">Close</a>
                                <div align="center">
                                { this.state.is_loading ? <Loading /> : (
                                    <div dangerouslySetInnerHTML={this.renderBodyContents()}></div>
                                ) }
                                { this.props.showCloseButton ? ( <a href="#!" onClick={() => {
                                    document.getElementById("closeOneAttemptUsedModal").click();
                                    this.props.history.push(`${this.props.site.site_path}/`);
                                }}  className="btn btn-dark btn-close-question" to={`${this.props.site.site_path}/`}>Close Window</a>) : '' }
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    site : state.siteReducer,
});

export default connect(mapStateToProps, {
})(OneAttemptUsedModal);